const Lambdas = () => import('@/components/admin/billing/lambdas/Lambdas');
const SystemEvents = () => import('@/components/admin/dashboard/SystemEvents');
const UserList = () => import('@/components/admin/user-manager/UserList');
const UserCreateEdit = () => import('@/components/admin/user-manager/UserCreateEdit.vue');

export const admin = [
    {
        path: '/admin',
        component: Lambdas
    },
    {
        path: '/system-events',
        name: 'systemEvents',
        component: SystemEvents,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/user-manager',
        name: 'userManager',
        component: UserList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/user-manager/:action',
        name: 'userCreate',
        component: UserCreateEdit,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/user-manager/:action/:userId',
        name: 'userEdit',
        component: UserCreateEdit,
        meta: {
            requiresAuth: true
        }
    }
];
