<template>
    <div>
        <label>{{ state.title }}</label>
        <el-date-picker
            v-model="state.selectedDate"
            type="date"
            :clear-icon="ElIconCircleCloseOutline"
            @change="paramsChanged"
        >
        </el-date-picker>
    </div>
</template>

<script>
import { CircleClose as ElIconCircleCloseOutline } from '@element-plus/icons-vue';
import moment from 'moment-timezone';

export default {
    data() {
        return {
            state: {
                title: this.node.title,
                fullKey: null,
                selectedDate: null
            },
            ElIconCircleCloseOutline
        };
    },
    props: ['node', 'parentKey', 'loadState'],
    mounted() {
        this.state.fullKey = [this.parentKey, this.node.key]
            .map((p) => (p || '').trim()) // remove nulls, trim results
            .filter((p) => !!p.length)
            .join('.'); // filter zero length

        if (this.loadState && this.loadState[this.state.fullKey]) {
            this.state.selectedDate = moment(
                this.loadState[this.state.fullKey].replace(/'/g, '')
            ).utc();
        }

        if (this.node.default && !this.state.selectedDate) {
            let def = this.node.default;
            let unit = 'days';
            let amount = 0;

            // a few convenience things
            if (def === 'today') amount = 0;
            else if (def === 'yesterday') amount = -1;
            else if (def === 'tomorrow') amount = 1;
            else {
                // parse out units and amount
                def = def.split(',').map((v) => v.trim());
                amount = parseInt(def[0]);
                unit = def[1];
            }

            this.state.selectedDate = moment().utc().add(amount, unit);

            // emit the state to the parents
            this.paramsChanged();
        }
    },
    methods: {
        paramsChanged() {
            if (this.state.fullKey.length > 0) {
                this.$emit('changed', {
                    [this.state.fullKey]: moment(this.state.selectedDate).format(
                        'YYYY-MM-DD'
                    )
                });
            } else {
                console.log(
                    'value just changed in a datePicker component but there was no key?'
                );
            }
        }
    }
};
</script>
