/**
 * App needs to redirect between Okta's authState and a subverted session manager for external logins.
 */
import { isExternallyAuthenticated, getAuthKey } from '@/assets/js/common/permissions';

// export the Vue plugin installer
export default {
    methods: {
        /**
         * an "isAuthenticated" equivalent that will first check to see if the user us externally
         * authenticated, otherwise will pass out whatever Okta thinks it wants to do.
         */
        canDoThings() {
            if (isExternallyAuthenticated()) {
                return true;
            } else {
                return getAuthKey();
            }
        }
    }
};
