<template>
    <div>
        <label class="label-inline">{{ node.title }}</label>
        <span v-if="node.required" class="required">*Required</span>
        <span class="info">{{ node.info || '' }}</span>
        <el-row>
            <el-col>
                <el-input
                    v-if="!password || showPasswordInput"
                    v-model="state.value"
                    @change="paramsChanged"
                    :class="{ invalid: invalidated }"
                    :show-password="password"
                ></el-input>
                <div v-if="password && !showPasswordInput">
                    [
                    <a href="" @click.prevent="showPasswordChallenge">-- click to edit password --</a>
                    ]
                </div>
                <div v-if="invalidated && node.validationMessage" style="color: red">
                    {{ node.validationMessage }}
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: ['node', 'parentKey', 'loadState', 'password'],

    data() {
        return {
            showPasswordInput: true,
            invalidated: false,
            state: {
                fullKey: null,
                value: null
            }
        };
    },

    mounted() {
    // todo: make this common bit of code more handy and less copy-pasta
        this.state.fullKey = [this.parentKey, this.node.key]
            .map((p) => (p || '').trim()) // remove nulls, trim results
            .filter((p) => !!p.length)
            .join('.'); // filter zero length

        const tmp = this.loadState ? this.loadState[this.state.fullKey] : null;

        if (tmp && tmp.length > 0) {
            if (this.password) this.showPasswordInput = false;

            if ((tmp + '').startsWith('<default>') && this.node.default) {
                this.state.value = this.node.default;
            } else {
                this.state.value = tmp === '<void>' ? '' : tmp;
            }
        } else if (this.node.default) {
            this.state.value = this.node.default;
        }

        setTimeout(() => this.paramsChanged(), 500);

        if (this.node.required || this.node.validation) {
            this.$emit('validatorBubble', {
                register: {
                    [this.state.fullKey]: (reset) => this.validationProcess(reset)
                }
            });
        }
    },

    beforeUnmount() {
        if (this.node.required) {
            this.emitter.emit('validatorBubble', { deregister: this.state.fullKey });
        }
    },

    methods: {
        validationProcess(reset) {
            if (!reset) {
                if (this.node.required || this.node.validation) {
                    if (!this.node.validation) {
                        this.invalidated =
              !this.state.value || !this.state.value.trim() === '';
                    } else {
            let valFunc = eval(this.node.validation) // eslint-disable-line
                        this.invalidated = !valFunc(this.state.value);
                    }
                }
                return !this.invalidated;
            } else {
                this.invalidated = false;
            }
        },

        paramsChanged() {
            if (this.state.fullKey.length > 0) {
                let v = this.state.value;
                if (!this.password && (!v || v.trim().length < 1)) {
                    v = '<void>';
                } else if (!this.password && v === this.node.default) {
                    v = '<default>' + this.node.default;
                }

                if (this.password) {
                    v = (v || '').trim();
                    if (v.length < 1) v = null;
                }

                this.emitter.emit('changed', { [this.state.fullKey]: v });
            }
        },

        showPasswordChallenge() {
            const challenge = window.prompt(
                'Enter current password to edit the password value:'
            );
            if (challenge === this.state.value) {
                this.showPasswordInput = true;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/insights.scss';
</style>
