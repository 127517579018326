<template>
    <div class="template-group-wrapper">
        <div v-show="optional">
            [ <a href="" @click.prevent="optionalClicked">{{ optional }}</a> ]
        </div>

        <div v-show="!optional">
            <el-row
                v-for="(child, idx) in node.children || node"
                :node="child"
                :key="'group_' + idx"
            >
                <div class="template-group__template-node-wrapper w-100">
                    <template-node
                        :node="child"
                        :load-state="loadState"
                        :parent-key="state.fullKey"
                        :key="'group_' + idx"
                        @changed="paramsChanged"
                        @validatorBubble="validatorBubble"
                    ></template-node>
                </div>
            </el-row>
        </div>
    </div>
</template>

<script>
import TemplateNode from '@/components/insights/reports/templates/TemplateNode.vue';

/**
 * This is to allow configuration of a list of UI components, it's just a pass-through, bubbles up
 * the changed event, etc.
 */
export default {
    components: { TemplateNode },

    props: ['node', 'parentKey', 'loadState'],

    data() {
        return {
            optional: false,
            state: {
                // parent key and current key, make sure not null and trimmed, ensure there's something, then join with dots...
                fullKey: [this.parentKey, this.node.key]
                    .map((p) => (p || '').trim())
                    .filter((p) => !!p.length)
                    .join('.')
            }
        };
    },

    mounted() {
        this.optional = this.node.optional || false;
    },

    methods: {
        paramsChanged(dat) {
            this.$emit('changed', dat);
        },
        validatorBubble(dat) {
            this.$emit('validatorBubble', dat);
        },
        optionalClicked() {
            this.optional = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.template-group__template-node-wrapper {
    .el-card {
        width: 100%;
    }
}
</style>
