const ContextualIntelligenceList = () => import('@/components/marvel/ListContextualSegments');
const ContextualIntelligenceCreate = () => import('@/components/marvel/CreateUpdateContextualSegment');
const WeatherTriggerList = () => import('@/components/marvel/weather/WeatherList');
const WeatherTriggerCreateEdit = () => import('@/components/marvel/weather/WeatherCreateEdit');


export const contextual = [
    {
        path: '/contextual',
        component: ContextualIntelligenceList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/contextual/create',
        component: ContextualIntelligenceCreate,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/contextual/:id',
        component: ContextualIntelligenceCreate,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/weather-triggers',
        component: WeatherTriggerList,
        name: 'weatherTriggerList',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/weather-triggers/:triggerId/:action',
        component: WeatherTriggerCreateEdit,
        name: 'weatherTriggerCopyEdit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/weather-triggers/:action',
        component: WeatherTriggerCreateEdit,
        name: 'weatherTriggerCreate',
        meta: {
            requiresAuth: true
        }
    }
];
