import _ from 'lodash';
import { connectApi } from '@/lib/axios';

const state = {
    lineItems: [],
    lineRequests: [],
    lineCollections: [],
    lineFilterQuery: '',
    uploadFilterQuery: ''
};

const getters = {
};

const mutations = {
    SET_LINE_ITEMS(state, lines) {
        state.lineItems = lines.map((line) => ({
            ...line,
            dspJson: line.dspData ? JSON.parse(line.dspData) : null
        }));
    },
    SET_LINE_REQUESTS(state, lines) {
        state.lineRequests = lines.map(line => ({
            ...line,
            json: JSON.parse(line.requestJson)
        }));
    },
    UPDATE_LINE_STATUS(state, data) {
        for (let i = 0; i < state.lineItems.length; i++) {
            const currLine = state.lineItems[i];
            if (data.line.id === currLine.lineId) {
                const newLine = { ...currLine };
                newLine.dspJson.state = data.status;
                newLine.dspData = JSON.stringify(currLine.dspJson);
                state.lineItems.splice(i, 1, newLine);
                break;
            }
        }
    },
    SET_LINE_COLLECTIONS(state, allRequests) {
        const filenames = _.groupBy(allRequests, 'fileName');
        const collections = [];
        Object.keys(filenames).forEach((name) => {
            const currCollection = filenames[name].reduce((collection, request) => {
                if (!collection.ids) collection.ids = [];
                if (!collection.requests) collection.requests = [];
                if (!collection.statuses) collection.statuses = [];
                if (!collection.errors) collection.errors = [];
                collection.ids.push(request.lineId);
                collection.fileName = request.fileName;
                collection.createdBy = request.createdBy;
                collection.createdDate = request.createdDate;
                collection.requests.push(JSON.parse(request.requestJson));
                collection.statuses.push(request.status);
                collection.errors.push(request.error);
                return collection;
            }, {});
            collections.push(currCollection);
        });
        state.lineCollections = collections;
    },
    SET_FILTER_QUERY(state, query) {
        state.lineFilterQuery = query;
    },
    SET_UPLOAD_QUERY(state, query) {
        state.uploadFilterQuery = query;
    }
};

const actions = {
    FETCH_AUG_LINE_ITEMS: ({ commit, rootGetters }, params) => {
        return new Promise((resolve, reject) => {
            if (params && !params.sort) {
                params.sort = 'name';
            } else if (!params) {
                params = { sort: 'name' };
            }

            const advertiserId = rootGetters['advertiser/SELECTED_ADVERTISER_ID'];
            if (advertiserId > -1) params.advertiserId = advertiserId;

            connectApi.get('/aug-line-item', { params })
                .then(resp => {
                    commit('SET_LINE_ITEMS', resp.data);
                    resolve(resp.data);
                }).catch(err => {
                    console.warn('[Error] ' + JSON.stringify(err));
                    reject(err.response);
                });
        });
    },
    GET_REQUEST_COLLECTIONS: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            if (params && !params.sort) {
                params.sort = 'createdBy';
            } else if (!params) {
                params = { sort: 'createdBy' };
            }
            connectApi.get('/aug-line-item/requests', { params })
                .then(resp => {
                    commit('SET_LINE_COLLECTIONS', resp.data);
                    resolve(resp.data);
                }).catch(err => {
                    console.warn('[Error] ' + JSON.stringify(err));
                    reject(err.response);
                });
        });
    },
    DOWNLOAD_SPREADSHEET: (_, params) => {
        return new Promise((resolve, reject) => {
            connectApi.post('/aug-line-item/download', params)
                .then(resp => {
                    resolve(resp.data);
                }).catch(err => {
                    console.warn('[Error] ' + JSON.stringify(err));
                    reject(err.response);
                });
        });
    },
    UPLOAD_SPREADSHEET: (_, params) => {
        return new Promise((resolve, reject) => {
            connectApi.post('/aug-line-item/upload', params)
                .then(resp => {
                    resolve(resp.data);
                }).catch(err => {
                    console.warn('[Error] ' + JSON.stringify(err));
                    reject(err.response);
                });
        });
    },
    FETCH_REQUEST_DATA: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            connectApi.get('/aug-line-item/requests', { params })
                .then(resp => {
                    commit('SET_LINE_REQUESTS', resp.data);
                    resolve(resp.data);
                }).catch(err => {
                    console.warn('[Error] ' + JSON.stringify(err));
                    reject(err.response);
                });
        });
    },
    UPDATE_LINE_STATUS: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            connectApi.put('/aug-line-item', params)
                .then(resp => {
                    commit('UPDATE_LINE_STATUS', params);
                    resolve(resp.data);
                }).catch(err => {
                    console.warn('[Error] ' + JSON.stringify(err));
                    reject(err.response);
                });
        });
    }
};

export default {
    namespaced: true, // auto namespace this module with its name
    state,
    getters,
    mutations,
    actions
};
