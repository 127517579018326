import AppInstance from '@/app';
import { atsApi } from '@/lib/axios';
import { logoutExternal } from '@/assets/js/common/permissions';
import {LOCAL_USER_PROFILE, LOCAL_USER_GROUPS} from "@/components/marvel/constants";

const state = {
    user: {
        claims: null,
        groups: [],
        groupMembership: {},
        profile: {
            firstName: null,
            lastName: null
        },
        id: null,
        authenticated: false,
        token: null,
        oktaToken: null
    },
    securityGroups: []
};

const getters = {
    USER_CLAIMS: store => store.user.claims,
    USER_NAME: store => store.user.profile.firstName,
    USER_LAST_NAME: store => store.user.profile.lastName,
    USER_ID: store => store.user.id,
    USER: store => store.user,
    IS_AUTHENTICATED: store => store.user.authenticated,
    HAS_GROUPS: store => store.user.groups.length > 0,
    USER_GROUPS: store => store.user.groups,
    USER_PROFILE: store => store.user.profile,
    AUTH_COOKIE: store => store.user.token,
    OKTA_TOKEN: store => store.user.oktaToken,
    GROUP_MEMBERSHIP: () => {
        const map = {};
        for (const group of state.user.groups) {
            map[group] = true;
        }
        return map;
    },
    IS_ADMIN: store => store.user.groups.includes('ats_admin-group')
};

const mutations = {
    REGISTER_USER_CLAIMS(state, claims) {
        state.user.claims = claims;
    },
    REGISTER_USER(state, user) {
        state.user.groups = user.groups;
        state.user.id = user.userId;
        const name = user.displayName.split(' ');
        state.user.profile.firstName = name[0];
        state.user.profile.lastName = name[1];
    },
    REGISTER_USER_PROFILE(state, profile) {
        if (profile) {
            window.localStorage.setItem(LOCAL_USER_PROFILE, JSON.stringify(profile));
            state.user.profile = profile;
        } else console.warn('User profile not found', profile);
    },
    REGISTER_USER_ID(state, id) {
        // TODO: remove once unnecessary
        // NOTE: This logic has been added to assist as we migrate from our old user ids,
        // which were strings to our new ids, which are integers. After some time, it should
        // become unnecessary.
        id = ('' + id).replace(/[",\\]/g, '');
        id = /^\d+$/.test(id + '') ? parseInt(id, 10) : id;
        if (id) window.localStorage.setItem('user.id', id);
        state.user.id = id;
    },
    REGISTER_USER_GROUPS(state, groups) {
        if (groups) window.localStorage.setItem(LOCAL_USER_GROUPS, JSON.stringify(groups));
        state.user.groups = groups || [];
        for (const group of state.user.groups) {
            AppInstance.set(state.user.groupMembership, group, true);
        }
    },
    RESET_USER(state) {
        state.user = {
            groups: [],
            groupMembership: {},
            profile: {},
            id: null,
            authenticated: false,
            token: null
        };
    },
    SET_TOKEN(state, token) {
        state.user.token = token;
    },
    SET_OKTA_TOKEN(state, token) {
        state.user.oktaToken = token;
    },
    SET_SECURITY_GROUPS(state, groups) {
        state.securityGroups = groups;
    }
};

const actions = {

    FETCH_SECURITY_GROUPS: async ({ commit, rootGetters }) => {
        try {
            if (!rootGetters['user/USER_GROUPS'].length) {
                throw new Error('No user groups');
            }
            const resp = await atsApi.get('/security-groups', {
                params: {
                    groups: rootGetters['user/USER_GROUPS'].join(',')
                }
            });
            commit('SET_SECURITY_GROUPS', resp.data);
            return resp;
        } catch (e) {
            throw new Error(`Error fetching security groups. ${e}`);
        }
    },

    FETCH_USER: async ({ commit }, email) => {
        const resp = await atsApi.get('/userAdmin/getUser/' + window.encodeURI(email));
        if (!resp || !resp.data || !resp.data.user) throw new Error('User not found.');
        commit('REGISTER_USER', resp.data.user);
        return resp.data.user;
    },

    FETCH_USER_GROUPS: async ({ commit }, email) => {
        const resp = await atsApi.get('/userAdmin/getUser/' + window.encodeURI(email));
        const groups = resp && resp.data && resp.data.user && resp.data.user.groups;
        commit('REGISTER_USER_GROUPS', groups);
        return groups;
    },

    LOG_OUT: ({ commit }) => {
        commit('RESET_USER');
        window.localStorage.removeItem(LOCAL_USER_PROFILE);
        window.localStorage.removeItem(LOCAL_USER_GROUPS);
        window.localStorage.removeItem('user.id');
        logoutExternal();
    },

    READ_AUTH_COOKIE: ({ commit }) => {
        const name = 'TOKEN=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                const token = c.substring(name.length, c.length);
                commit('SET_TOKEN', token);
                return token;
            }
        }
        commit('SET_TOKEN', null);
        return '';
    },

    REFRESH_USER_GROUPS: ({ commit, rootGetters }) => {
        if (rootGetters['USER/USER_GROUPS']) return;
        const groups = window.localStorage.getItem(LOCAL_USER_GROUPS);
        commit('REGISTER_USER_GROUPS', JSON.parse(groups));
    },

    REFRESH_USER_ID: ({ commit, rootGetters }) => {
        if (rootGetters['USER/USER_ID']) return;
        const id = window.localStorage.getItem('user.id');
        commit('REGISTER_USER_ID', id);
    },

    TRIGGER_PASSWORD_RESET: (_, email) => {
        return new Promise((resolve, reject) => {
            atsApi.post('/auth/ats/forgotPassword', {
                email
            }).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    VERIFY_USER: ({ dispatch }, token) => {
        return new Promise((resolve, reject) => {
            atsApi.post('/auth/ats', {
                token
            }).then((resp) => {

                const status = resp.data.message;

                if (status === 'ACTIVE') {
                    console.log('active token', status);
                    resolve(resp.data);
                } else {
                    console.log('inactive token', status);
                    dispatch('LOG_OUT');
                    reject('Invalid token');
                }

            }).catch((err) => {
                reject(err);
            });
        });
    },

    WRITE_AUTH_COOKIE: (_, cookie) => {
        const domain = /dentsuactivation\.com/.test(window.location.hostname)
            ? '.dentsuactivation.com'
            : '';
        const expiresAt = new Date(cookie.expires).toUTCString();
        document.cookie = `TOKEN=${cookie.token};expires=${expiresAt};path=/;domain=${domain};`;
    }
};

export default {
    namespaced: true, // auto namespace this module with its name
    state,
    getters,
    mutations,
    actions
};
