<template>
    <el-row>
        <el-col>
            <el-checkbox v-model="state.checked" @change="paramsChanged">
                {{ node.title }}
            </el-checkbox>
            <span class="info">{{ node.info || '' }}</span>
        </el-col>
    </el-row>
</template>

<script>
export default {
    props: ['node', 'parentKey', 'loadState'],

    data() {
        return {
            state: {
                fullKey: null,
                checked: false
            }
        };
    },

    mounted() {
    // todo: make this common bit of code more handy and less copy-pasta
        this.state.fullKey = [this.parentKey, this.node.key]
            .map((p) => (p || '').trim()) // remove nulls, trim results
            .filter((p) => !!p.length)
            .join('.'); // filter zero length

        const tmp = this.loadState ? this.loadState[this.state.fullKey] : null;

        if (tmp && tmp.length > 0) {
            if ((tmp + '').startsWith('<default>') && this.node.default) {
                this.state.checked = this.node.default === 'checked';
            } else {
                this.state.checked = tmp.trim() !== '<void>';
            }
        } else if (this.node.default === 'checked') {
            this.state.checked = true;
        }

        setTimeout(() => this.paramsChanged(), 500);
    },

    methods: {
        paramsChanged() {
            let v = this.state.checked ? this.node.value : '<void>';

            if (this.state.checked && this.node.default === 'checked') {
                v = '<default>' + v;
            }

            if (this.state.fullKey.length > 0) {
                this.$emit('changed', { [this.state.fullKey]: v });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
span.info {
  margin-left: 30px;
}
</style>
