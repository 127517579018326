<template>
    <div>
        <label class="label-inline">{{ state.title }}</label>
        <span v-if="node.required" class="required">*Required</span>
        <span class="info">{{ node.info || '' }}</span>
        <el-row>
            <el-col>
                <el-select
                    v-model="state.selected"
                    placeholder="Select"
                    clearable
                    :multiple="state.multiple"
                    :class="{ invalid: invalidated }"
                    @change="selectionChanged"
                >
                    <el-option
                        v-for="option in state.options"
                        :key="option.index"
                        :label="option.title"
                        :value="option.index"
                    >
                    </el-option>
                </el-select>
                <div v-if="invalidated && node.validationMessage" style="color: red">
                    {{ node.validationMessage }}
                </div>
            </el-col>
        </el-row>

        <el-row
            v-for="(card, idx) in state.cards"
            :key="'state.fullKey[' + idx + ']'"
        >
            <el-card shadow="never" class="w-100">
                <slot name="header">
                    <label class="title">{{ state.title }}: {{ card.title }}</label>
                </slot>
                <template-node
                    :key="state.updateKey"
                    :node="card.value"
                    :load-state="loadState"
                    :parent-key="state.fullKey"
                    @changed="paramsChanged"
                    @validatorBubble="validatorBubble"
                ></template-node>
            </el-card>
        </el-row>
    </div>
</template>

<script>
import TemplateNode from '@/components/insights/reports/templates/TemplateNode.vue';

export default {
    name: 'SelectNode',
    components: { TemplateNode },
    props: ['node', 'parentKey', 'loadState'],

    data() {
        return {
            invalidated: false,

            state: {
                title: this.node.title,
                fullKey: null,
                updateKey: null,
                selected: null,
                options: [],
                cards: [],
                multiple: !!this.node.multiple,
                loadState: this.loadState
            }
        };
    },

    mounted() {
        this.state.fullKey = [this.parentKey, this.node.key]
            .map((p) => (p || '').trim()) // remove nulls, trim results
            .filter((p) => !!p.length)
            .join('.'); // filter zero length

        this.state.options = [...this.node.options];

        // anything set for the current key?
        let loadValue = this.state.loadState
            ? this.state.loadState[this.state.fullKey + '._']
            : null;

        if (!loadValue && this.node.default) {
            loadValue =
        typeof this.node.default === 'string'
            ? this.node.default.split(',').map((s) => s.trim())
            : this.node.default;
            setTimeout(() => this.selectionChanged(loadValue), 500);
        }

        if (loadValue) {
            // make array of inbound for easier handling
            if (!Array.isArray(loadValue)) loadValue = [loadValue];

            this.state.selected = this.node.multiple ? loadValue : loadValue[0];

            // complex objects are nested UI to show as cards
            this.state.cards = this.node.options.filter(
                (o) => loadValue.includes(o.index) && typeof o.value === 'object'
            );
            if (this.state.cards.length) {
                this.state.updateKey = new Date().getTime();
            }
        }

        // clear out, just in case something reloads this component that it doesn't reset a choice
        if (this.state.loadState) {
            this.state.loadState[this.state.fullKey + '._'] = null;
        }

        if (this.node.required) {
            this.$emit('validatorBubble', {
                register: {
                    [this.state.fullKey]: (reset) => this.validationProcess(reset)
                }
            });
        }
    },

    beforeUnmount() {
        if (this.node.required) {
            this.$emit('validatorBubble', { deregister: this.state.fullKey });
        }
    },

    methods: {
        validationProcess(reset) {
            if (!reset) {
                if (
                    this.node.required &&
          (!this.state.selected ||
            (this.node.multiple ? !this.state.selected.length : false))
                ) {
                    this.invalidated = true;
                }
                return !this.invalidated;
            } else {
                this.invalidated = false;
            }
        },

        selectionChanged(idx) {
            // make into arrays of strings
            if (!idx || idx === '') idx = [];
            if (idx && !Array.isArray(idx)) idx = [idx];
            idx = idx.map((idx) => idx + '');

            // clear out the child cards as we will rebuild any required
            this.state.cards = [];

            // default the event to a resetting...
            let eventData = {
                [this.state.fullKey]: undefined,
                [this.state.fullKey + '._']: undefined
            };

            if (idx.length) {
                const selected = this.node.options.filter((o) =>
                    idx.includes(o.index + '')
                );

                // any child objects become cards
                this.state.cards = selected.filter((s) => typeof s.value === 'object');

                if (this.state.cards.length) {
                    this.state.updateKey = new Date().getTime();
                    eventData = {
                        [this.state.fullKey]: selected[0].key
                    };
                } else {
                    eventData = {
                        [this.state.fullKey]: !this.node.multiple
                            ? selected.length
                                ? selected[0].value
                                : undefined
                            : selected.map((s) => s.value)
                    };
                }

                // internal reference ("clearing key") to remember how to set itself
                eventData[this.state.fullKey + '._'] = selected.map((s) => s.index);
            }

            if (this.state.fullKey.length > 0) {
                this.$emit('changed', eventData);
            } else {
                console.log(
                    'value just changed in a select component but there was no key?'
                );
            }
        },

        paramsChanged(dat) {
            this.$emit('changed', dat);
        },

        validatorBubble(dat) {
            this.$emit('validatorBubble', dat);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/insights.scss';

.card {
  margin-left: 35px;
  width: 97%;
}
</style>
