import { contextual } from './pillarRoutes/contextualRoutes';
import { optics } from './pillarRoutes/opticsRoutes';
import { insights } from './pillarRoutes/insightsRoutes';
import { admin } from './pillarRoutes/adminRoutes';

const ErrorP = () => import('@/components/Error');
const Landing = () => import('@/components/Landing');
const Login = () => import('@/components/Login');
const NotFound = () => import('@/components/NotFound');
const ForgotPassword = () => import('@/components/ForgotPassword');
const AlertManager = () => import('@/components/alerts/AlertManager');
const AlertEditor = () => import('@/components/alerts/AlertEditor');

export const routes = [
    ...contextual,
    ...optics,
    ...insights,
    ...admin,
    {
        path: '/landing',
        component: Landing
    },
    {
        path: '/alert-manager',
        component: AlertManager,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/alert-manager/edit/:advertiserId',
        component: AlertEditor,
        props: (route) => ({
            email: route.query.email,
            advertiserId: route.params.advertiserId,
            clone: route.query.clone
        }),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/alert-manager/new',
        name: 'alertCreate',
        component: AlertEditor,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'error',
        path: '/error',
        props: true,
        component: ErrorP
    },
    {
        path: '/forgotPassword',
        component: ForgotPassword
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/:catchAll(.*)',
        component: NotFound
    }
];
