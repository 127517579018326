import { atsApi } from '@/lib/axios';

const state = {
    alerts: [],
    configs: [],
    triggered: {} // accessed by [alertId][advertiserId] = Object[] of Subscribers
};

const getters = {
    GET_ALERTS: store => store.alerts,
    GET_ALERT_CONFIGS: store => store.configs,
    GET_TRIGGERED_SUBS: store => store.triggered,
    HAS_PENDING: (store) => (alert) => {
        return Object.keys(store.triggered).some((alertId) => {
            const subs = store.triggered[alertId][alert.advertiserId];
            if (subs) {
                const pending = subs.filter((sub) => {
                    return sub.subarn === 'PendingConfirmation' &&
                            sub.email === alert.email;
                });
                if (pending.length) {
                    return true;
                }
            }
            return false;
        });
    }
};

const mutations = {
    SET_ALERTS(state, alerts) {
        state.alerts = alerts;
    },
    SET_ALERT_CONFIGS(state, configs) {
        state.configs = configs;
    },
    SET_TRIGGERED_SUBS(state, data) {
        state.triggered = data;
    },
    ADD_TRIGGERED_SUBS(state, payload) {
        // sets all subscribers for an alert ID
        if (state.triggered[payload.id]) {
            state.triggered[payload.id][payload.advertiserId] = payload.data;
        } else {
            state.triggered[payload.id] = {
                [payload.advertiserId]: payload.data
            };
        }
    },
    ADD_TRIGGERED_SUB(state, payload) {
        // to keep store in sync with subscribe/unsub without refetch
        if (state.triggered[payload.id] &&
            state.triggered[payload.id][payload.advertiserId]) {
            state.triggered[payload.id][payload.advertiserId].push(payload.data);
        } else {
            state.triggered[payload.id][payload.advertiserId] = [payload.data];
        }
    },
    REMOVE_TRIGGERED_SUB(state, payload) {
        // to keep store in sync with subscribe/unsub without refetch
        const { alertId, advertiserId, email } = payload;
        const allSubs = state.triggered[alertId][advertiserId];
        const indexToRemove = allSubs.findIndex((sub) => {
            return email === sub.email;
        });
        state.triggered[alertId][advertiserId].splice(indexToRemove, 1);
    },
    REMOVE_ALERTS(state, advertiserId) {
        Object.keys(state.triggered).forEach((alrt) => {
            if (state.triggered[alrt][advertiserId]) {
                delete state.triggered[alrt][advertiserId];
            }
        });
    }
};

const actions = {
    AUTO_SUBSCRIBE_DAILY: (_, payload) => {
        return new Promise((resolve, reject) => {
            atsApi.post('/alert-manager/daily/autosubscribe', payload)
                .then(resp => {
                    resolve(resp.data);
                }).catch(err => {
                    reject(err);
                });
        });
    },
    FETCH_ALERT_LIST: ({ commit }) => {
        return new Promise((resolve, reject) => {
            atsApi.get('/alert-manager')
                .then((resp) => {
                    commit('SET_ALERTS', resp.data.list);
                    resolve(resp.data);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    FETCH_ALERT_CONFIGS: ({ commit }) => {
        return new Promise((resolve, reject) => {
            atsApi.get('/alert-manager/configs')
                .then((resp) => {
                    commit('SET_ALERT_CONFIGS', resp.data.configs);
                    resolve(resp.data.configs);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    CREATE_ALERT_CONFIG: (_, payload) => {
        return new Promise((resolve, reject) => {
            atsApi.post('/alert-manager/configs', {
                advertiserId: payload.advertiserId,
                email: payload.email
            }).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    DELETE_ALERT_CONFIG: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            atsApi.delete(`/alert-manager/configs/${payload.advertiserId}?email=${payload.email}`)
                .then((resp) => {
                    commit('REMOVE_ALERTS', payload.advertiserId);
                    resolve(resp.data);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    FETCH_DAILY_ALERTS: (_, payload) => {
        return new Promise((resolve, reject) => {
            atsApi.get(`/alert-manager/daily?advertiserId=${payload.advertiserId}&advertiserGroupId=${payload.advertiserGroupId}&email=${payload.email}`)
                .then((resp) => {
                    resolve(resp.data);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    FETCH_TRIGGERED_ALERTS: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            atsApi.get(`/alert-manager/triggered?advertiserId=${payload.advertiserId}&alertId=${payload.alertId}`)
                .then((resp) => {
                    commit('ADD_TRIGGERED_SUBS', {
                        id: payload.alertId,
                        advertiserId: payload.advertiserId,
                        data: resp.data
                    });
                    resolve(resp.data);
                }).catch((err) => {
                    commit('ADD_TRIGGERED_SUBS', {
                        id: payload.alertId,
                        advertiserId: payload.advertiserId,
                        data: []
                    });
                    reject(err);
                });
        });
    },
    FETCH_ALL_TRIGGERED_ALERTS: ({ dispatch, state }, advertiserId) => {
        const trigPromises = [];
        state.alerts.forEach((a) => {
            const trigPromise = dispatch('FETCH_TRIGGERED_ALERTS', {
                alertId: a.ID,
                advertiserId
            });
            trigPromises.push(trigPromise);
        });
        return Promise.all(trigPromises);
    },
    SUBSCRIBE_TRIGGERED: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            atsApi.post('/alert-manager/triggered/subscribe', {
                advertiserId: payload.advertiserId,
                alertId: payload.alertId,
                email: payload.email
            })
                .then((resp) => {
                    commit('ADD_TRIGGERED_SUB', {
                        advertiserId: payload.advertiserId,
                        id: payload.alertId,
                        data: resp.data
                    });
                    resolve(resp.data);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    UNSUBSCRIBE_TRIGGERED: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            atsApi.delete(`/alert-manager/triggered/unsubscribe?advertiserId=${payload.advertiserId}&alertId=${payload.alertId}&email=${payload.email}`)
                .then((resp) => {
                    commit('REMOVE_TRIGGERED_SUB', payload);
                    resolve(resp.data);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    SUBSCRIBE_DAILY: (_, payload) => {
        return new Promise((resolve, reject) => {
            atsApi.post('/alert-manager/daily/subscribe', {
                advertiserId: payload.advertiserId,
                alertId: payload.alertId,
                email: payload.email
            })
                .then((resp) => {
                    resolve(resp.data);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    UNSUBSCRIBE_DAILY: (_, payload) => {
        return new Promise((resolve, reject) => {
            atsApi.delete(`/alert-manager/daily/unsubscribe?advertiserId=${payload.advertiserId}&alertId=${payload.alertId}&email=${payload.email}`)
                .then((resp) => {
                    resolve(resp.data);
                }).catch((err) => {
                    reject(err);
                });
        });
    }
};

export default {
    namespaced: true, // auto namespace this module with its name
    state,
    getters,
    mutations,
    actions
};
