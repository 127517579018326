<template><span></span></template>

<script>
export default {
    props: ['node', 'parentKey', 'loadState'],

    data() {
        return {
            state: {
                fullKey: null,
                value: null
            }
        };
    },

    mounted() {
    // todo: make this common bit of code more handy and less copy-pasta
        this.state.fullKey = [this.parentKey, this.node.key]
            .map((p) => (p || '').trim()) // remove nulls, trim results
            .filter((p) => !!p.length)
            .join('.'); // filter zero length

        this.state.value = this.node.value;

        this.$emit('changed', {
            [this.state.fullKey]: this.node.value
        });
    }
};
</script>
