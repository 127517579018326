import {LOCAL_SIDE_BAR_VISIBLE, LOCAL_TARGETS} from "@/components/marvel/constants";

export default {
    setSidebar(state, data) {
        window.localStorage.setItem(LOCAL_SIDE_BAR_VISIBLE, data);
        state.sidebarVisible = data;
    },
    loadTargets(state, data) {
        state.charts.targets = data.targets;
    },
    loadCachedAdvertiser(state, data) {
        state.menus.selectedAdvertiser = data.adv;
    },
    loadCachedIO(state, data) {
        state.menus.selectedIO = data.io;
    },
    toggleSpinner(state, toggleOn) {
        state.spinner += (toggleOn ? 1 : -1);
    },
    updateIO(state, data) {
        state.menus.selectedIO = data.selection;
        state.menus.isWatched = data.isWatched;
    },
    updateAdvertiser(state, data) {
        state.menus.selectedAdvertiser = data.advertiser;
    },
    updateTargets(state, data) {
        const selIO = state.menus.selectedIO.insertion_order_id;
        const selAdv = state.menus.selectedIO.advertiser_id;
        if (data.target === null || data.target === undefined) {
            if (state.charts.targets[selAdv] && state.charts.targets[selAdv][selIO]) {
                delete state.charts.targets[selAdv][selIO];
                if (!Object.keys(state.charts.targets[selAdv]).length) {
                    delete state.charts.targets[selAdv];
                }
            }
        } else {
            if (!state.charts.targets[selAdv]) {
                state.charts.targets[selAdv] = {};
            }
            state.charts.targets[selAdv][selIO] = data.target;
        }
        window.localStorage.setItem(LOCAL_TARGETS, JSON.stringify(state.charts.targets));
    }
};
