import AppInstance from '@/app';
import store from '../../../store';
import { atsApi } from '@/lib/axios';

// TODO: refactor into meta on routes obj {..., roles: ['ats_alertsBETA-app']}
const routeMap = {
    // nav
    '/': 'Everyone',
    '/alert-manager': 'ats_alertsBETA-app',
    '/user-manager': 'aim_querybuilder-app',
    // connect
    '/bidder-fees': 'audienceconnect_fees-app',
    '/campaigns': 'audienceconnect_campaignbuilderBETA-app',
    '/campaigns-create': 'audienceconnect_campaignbuilderBETA-app',
    '/campaigns-edit': 'audienceconnect_campaignbuilderBETA-app',
    '/creatives': 'audienceconnect_internal-user',
    // '/error': 'audienceconnect_internal-user',
    '/advertiser-manager': 'audienceconnect_advertisers',
    '/advertiser-manager-edit': 'audienceconnect_advertisers',
    '/campaign-display': 'audienceconnect_internal-user', // TODO: test delete
    '/mockup-validator': 'audienceconnect_mockup-app',
    // contextual intelligence
    '/weather-triggers': 'contextualintelligence_internal-user',
    '/weather-triggers/create': 'contextualintelligence_internal-user',
    // optics
    '/segment-overlap': 'audienceoptics_advantagesegments-app',
    '/tag-manager': 'audienceoptics_tagbuilder-app',
    '/tag-manager-create': 'audienceoptics_tagbuilder-app',
    '/tag-manager-edit': 'audienceoptics_tagbuilder-app',
    '/dan-tag-segment-builder': 'audienceoptics_taxonomyexplorer-app',
    '/dan-tag-segment-builder-create': 'audienceoptics_taxonomyexplorer-app',
    '/behavioral-segment-builder': 'audienceoptics_contextualsegments-app',
    '/advantage-segment-builder': 'audienceoptics_audiencebuilder-app',
    '/seed-segment-builder': 'audienceoptics_seedbuilder-app',
    // insights
    '/cars': 'aim_cars-app',
    '/reports': 'aim_querybuilder-app',
    '/audience-explorer': 'aim_audienceexplorer-app',
    // contextual
    '/contextual': 'contextual-BETA-app',
    '/contextual/create': 'contextual-BETA-app',
    // admin
    '/admin': 'ats_admin-group',
    '/system-events': 'ats_admin-group'
};

// init some variables for external login
let externalAuthUser = null;

// snoop for a key in local storage (opening new tabs)...
let externalSessionKey = localStorage.getItem('externalSessionKey');

/**
 * Okta isn't friendly, and it's hard to turn off... so this does the external login
 * check for a session key, and if it returns user details from the session key, then
 * it will replace the heart of Okta (isAuthenticated and getUser) with funcitons that
 * returns the external user deets.
 */
async function loginDirectlyAndKillOktaWithFireIfAllowed(sessionKey) {
    if (!externalAuthUser) {
        // make sure this gets removed, it might be expired
        localStorage.removeItem('externalSessionKey');

        try {
            const authResult = await atsApi.post('/userAdmin/externalLogin', {
                sessionKey
            });
            if (authResult && authResult.data) {
                // set our externally sourced user, make sure there's userId as a sanity check
                externalAuthUser = authResult.data.user;

                if (!externalAuthUser.userId) externalAuthUser = null;
                else {
                    localStorage.setItem('externalSessionKey', sessionKey);
                }
            }
        } catch (ex) {
            console.log(ex);
            externalAuthUser = null;
        }
    }

    // succeeds and moves to local storage or nulled
    externalSessionKey = null;

    // now the kill with fire part...
    if (externalAuthUser) {
        AppInstance.config.globalProperties.$auth._backupAuth =
          AppInstance.config.globalProperties.$auth.isAuthenticated;
        AppInstance.config.globalProperties.$auth._backupGetUser =
          AppInstance.config.globalProperties.$auth.getUser;

        // replace the auth check function with something more 'robust' for our external user
        AppInstance.config.globalProperties.$auth.isAuthenticated = () => true;
        AppInstance.config.globalProperties.$auth.getUser = () => externalAuthUser;
    }

    // return the user for confirmation
    return externalAuthUser;
}

/**
 * make sure external users can log out by choice
 */
function logoutExternal() {
    localStorage.removeItem('externalSessionKey');
    externalAuthUser = null;
    externalSessionKey = null;

    // unkill okta
    if (AppInstance.config.globalProperties.$auth._backupAuth) {
        AppInstance.config.globalProperties.$auth.isAuthenticated =
          AppInstance.config.globalProperties.$auth._backupAuth;
    }
    if (AppInstance.config.globalProperties.$auth._backupGetUser) {
        AppInstance.config.globalProperties.$auth.getUser =
          AppInstance.config.globalProperties.$auth._backupGetUser;
    }
}

async function isOktaAuthenticated() {
    if (externalSessionKey) {
        // once checked, the externalSessionKey goes away (succeeds and moves to local storage or nulled)
        await loginDirectlyAndKillOktaWithFireIfAllowed(externalSessionKey);
    }
    return AppInstance.config.globalProperties.$auth.isAuthenticated();
}

function getAuthKey() {
    if (store.getters['user/OKTA_TOKEN']) return store.getters['user/OKTA_TOKEN'];
    let oktaKeys = localStorage.getItem('okta-token-storage');
    let authKey = null;

    if (oktaKeys) {
        oktaKeys = JSON.parse(oktaKeys);
        authKey = oktaKeys?.accessToken?.accessToken;
    }

    if (authKey) store.commit('user/SET_OKTA_TOKEN', authKey);

    return authKey;
}

function getClaims() {
    // check whether the user's claims have come in.
    // if they haven't, call OKTA to get them.
    return store.getters['user/USER_CLAIMS'] || setClaims();
}

function isAuthorizedForApp(groups, appPath) {
    return groups && groups.length && groups.indexOf(getAppGroups(appPath)) > -1;
}

function isPublicRoute(groups, appPath) {
    return isAuthorizedForApp(groups, appPath) && getAppGroups(appPath) === undefined;
}

function isExternallyAuthenticated() {
    return externalSessionKey || externalAuthUser;
}

async function setClaims() {
    const claims = await AppInstance.config.globalProperties.$auth.getUser();
    store.commit('user/REGISTER_USER_CLAIMS', claims);
    return claims;
}

async function getGroups(email) {
    /* Check whether the user's groups have come. These are
       dependent on the user info in the claims. If not
       present, call the user service to get them. */
    let groups = store.getters['user/USER_GROUPS'];

    if (!groups || groups.length === 0) {
        try {
            await store.dispatch('user/FETCH_USER', email);
            groups = store.getters['user/USER_GROUPS'];
        } catch (e) {
            console.warn(e);
        }
    }
    return groups;
}

function getAdvertisers(groups) {
    // If we have user groups, we can also have advertisers. Check if we have them.
    // If we don't, go get them.
    const advertisers = store.getters['advertiser/ADVERTISERS'];
    return advertisers.length ? advertisers : groups.length ? store.dispatch('advertiser/FETCH_ADVERTISERS') : [];
}

function getAdvertiserGroups(groups) {
    // If we have user groups, we can also have advertiser groups. Check if we have them.
    // If we don't, go get them.
    const advertiserGroups = store.getters['advertiser/ADVERTISER_GROUPS_ACTIVE'];
    return advertiserGroups.length ? advertiserGroups : groups.length ? store.dispatch('advertiser/FETCH_ADVERTISER_GROUPS_ACTIVE') : [];
}

function getAppGroups(appPath) {
    return routeMap[appPath];
}

async function loadGlobalData() {
    const claims = await getClaims();
    let user = null;
    if (claims) {
        if (claims.email) {
            user = claims.email;
        } else {
            user = claims.preferred_username;
        }
    }
    const groups = await getGroups(user);
    const advertisers = groups && groups.length && await getAdvertisers(groups);
    const advertiserGroups = groups && groups.length && await getAdvertiserGroups(groups);

    return {
        claims,
        groups,
        advertisers,
        advertiserGroups
    };
}

async function getPermissions(appPath) {
    const { groups } = await loadGlobalData();
    const hasGroups = groups && groups.length;
    const authorizedForApp = isAuthorizedForApp(groups, appPath);
    const isPublic = isPublicRoute(groups, appPath);
    return { authorizedForApp, hasGroups, isPublic };
}

export {
    getAdvertisers,
    getAdvertiserGroups,
    getAppGroups,
    getAuthKey,
    getClaims,
    getGroups,
    getPermissions,
    isAuthorizedForApp,
    isOktaAuthenticated,
    isPublicRoute,
    loadGlobalData,
    setClaims,
    loginDirectlyAndKillOktaWithFireIfAllowed,
    logoutExternal,
    isExternallyAuthenticated
};
