import { STAGE } from '@/components/shared/env';

const OKTA_PROD_DOMAIN = 'login.mediaecosystem.io'; // 'myapps.dentsu.com';
const OKTA_PROD_ID = '0oa3fwx7oc2nA3nKY417'; // '0oa5l9qw3u4M0nzHt0i7';
const OKTA_DEV_DOMAIN = 'login.np.mediaecosystem.io'; // 'myapps-test.dentsu.com';
const OKTA_DEV_ID = '0oa3fgm2hrzwsnKSE417'; // '0oa57mb4hxzMRSq6M0i7';
const OKTA_PROD_SERVER_ID = 'aushtuc5mYJn1jAA2416'; // 'aus73umymnEithIGU0i7';
const OKTA_DEV_SERVER_ID = 'aus9o3cglQq9xwrOZ416'; // 'aus733ir4wIGJIgf80i7';
const OKTA_PROD = {
    domain: OKTA_PROD_DOMAIN,
    id: OKTA_PROD_ID,
    server: OKTA_PROD_SERVER_ID
};
const OKTA_DEV = {
    domain: OKTA_DEV_DOMAIN,
    id: OKTA_DEV_ID,
    server: OKTA_DEV_SERVER_ID
};
const OKTA_CONFIG_DEETS_MAP = {
    'connect-staging.accordant-ats.com': OKTA_PROD,
    'portal-staging.accordant-ats.com': OKTA_PROD,
    'insights-staging.accordant-ats.com': OKTA_PROD,
    'optics-staging.accordant-ats.com': OKTA_PROD,
    'dentsuactivation.com': OKTA_PROD,
    'me-dev.dentsuactivation.com': OKTA_DEV,
    'me-staging.dentsuactivation.com': OKTA_PROD,
    'lazarus.local:8080': OKTA_PROD
};

const OKTA_CALLBACK_PATH = '/login/callback';
const OKTA_LOGOUT_REDIRECT_PATH = '/login';
const LOCALHOST = 'lazarus.local:8080';
const PROD = 'dentsuactivation.com';
const STAGING_REGEX = /(insights|optics|connect|portal|me)?(-staging.|-dev.){1}(accordant-ats|dentsuactivation).com/;
const STAGING_MATCH = STAGING_REGEX.exec(window.location.hostname);
const STAGING = (STAGING_MATCH && STAGING_MATCH[1] && STAGING_MATCH[2]) ? STAGING_MATCH[0] : STAGING_MATCH;
const domain = STAGE === 'dev' ? STAGING || LOCALHOST : PROD;
const oktaConfigDeets = OKTA_CONFIG_DEETS_MAP[domain] || OKTA_PROD;
const oktaDomain = oktaConfigDeets.domain;
const clientId = oktaConfigDeets.id;
const serverId = oktaConfigDeets.server;

export default {
    clientId,
    issuer: `https://${oktaDomain}/oauth2/${serverId}`,
    redirectUri: `https://${domain}${OKTA_CALLBACK_PATH}`,
    postLogoutRedirectUri: `https://${domain}${OKTA_LOGOUT_REDIRECT_PATH}`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true
};
