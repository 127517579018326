import AppInstance from '@/app';
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import 'element-plus/dist/index.css';

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    AppInstance.component(key, component);
}

AppInstance.use(ElementPlus);
