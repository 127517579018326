<template>
    <el-dialog
        title="Submit Issue to Jira"
        :model-value="showJiraDialog"
        :before-close="closeDialog"
        class="jira-dialog"
    >
        <el-alert
            title=""
            type="success"
            v-if="submitSuccess"
            description="Success! Your issue has been created."
            @close="submitSuccess = false"
            show-icon
        >
        </el-alert>
        <el-alert
            title=""
            type="error"
            v-if="jiraError"
            description="Error submitting your ticket to Jira."
            @close="submitSuccess = false"
            show-icon
        >
        </el-alert>

        <p v-if="submitSuccess" class="jira-dialog__success-msg mb-1">
            <a target="_blank" :href="jiraSuccessUrl">
                {{ jiraSuccessUrl }}
            </a>
        </p>

        <!-- Errors -->
        <div class="jira-dialog__error-messages mb-1">
            <el-alert
                v-for="(error, index) in validity"
                :key="index"
                title=""
                type="error"
                :description="error"
                @close="removeValidation(index)"
                show-icon
            >
            </el-alert>
        </div>

        <div v-if="!submitSuccess">
            <el-row>
                <el-col>
                    <el-input
                        text
                        placeholder="Enter a summary for the issue"
                        v-model="jira.summary"
                        :class="{ invalid: validity.noSummary }"
                    ></el-input>
                </el-col>
            </el-row>

            <el-row>
                <el-col>
                    <el-input
                        text
                        placeholder="Advertiser Name"
                        v-model="jira.advertiserName"
                        :class="{ invalid: validity.noAdvertiser }"
                    ></el-input>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder="Enter a description for the issue"
                        v-model="jira.description"
                        :class="{ invalid: validity.noDescription }"
                    ></el-input>
                </el-col>
            </el-row>

            <slot name="footer">
                <span class="dialog-footer">
                    <el-button
                        @click="closeDialog"
                        plain
                        class="secondary"
                    >
                        Cancel
                    </el-button>
                    <el-button
                        :loading="loading"
                        type="primary"
                        @click="submitJiraTicket"
                    >
                        Submit
                    </el-button>
                </span>
            </slot>
        </div>
    </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { atsApi } from '@/lib/axios';
import ValidatorMixin from '@/mixins/validator';

export default {
    name: 'JiraDialog',
    props: ['showJiraDialog'],
    mixins: [ValidatorMixin],
    data() {
        return {
            jira: {
                action: null,
                summary: null,
                advertiserName: null,
                description: null
            },
            jiraIssueKey: null,
            loading: false,
            submitSuccess: false,
            jiraError: false,
            validity: {},
            jiraSuccessUrl: ''
        };
    },
    methods: {
        async submitJiraTicket() {
            const request = { ...this.jira };
            request.action = 'reportIssue';
            request.user = this.claims.email;
            request.page = this.$route.path;
            if (request.description && request.description.trim() !== '') {
                request.description = request.description.replace(/(\r\n|\n|\r)/gm, ' ');
            }

            if (this.validated()) {
                this.loading = true;
                try {
                    const result = await atsApi.post('/jira', request);
                    if (result && result.status === 200) {
                        this.loading = false;
                        this.submitSuccess = true;
                        this.jiraIssueKey = result.data.ticketKey;
                        this.jiraSuccessUrl = `https://accordantmedia.atlassian.net/browse/${this.jiraIssueKey}`;
                    } else {
                        throw new Error(result);
                    }
                } catch (err) {
                    console.log('Error in submitting JIRA Task');
                    console.log('err: ' + err);
                    this.loading = false;
                    this.jiraError = true;
                }
            }
        },
        closeDialog() {
            // Perform cleanup, i.e set to initial state
            for (const key in this.jira) {
                this.jira[key] = null;
            }
            this.jiraIssueKey = null;
            this.loading = false;
            this.submitSuccess = false;
            this.jiraError = false;
            this.validity = JSON.parse(JSON.stringify({}));

            this.$emit('closeJiraDialog');
        },
        validators() {
            return [
                {
                    key: 'noDescription',
                    valid: () => !this.validateEmpty(this.jira.description),
                    message: 'Please enter a description.'
                },
                {
                    key: 'noSummary',
                    valid: () => !this.validateEmpty(this.jira.summary),
                    message: 'Please enter a summary.'
                },
                {
                    key: 'noAdvertiser',
                    valid: () => !this.validateEmpty(this.jira.advertiserName),
                    message: 'Please enter an advertiser.'
                }
            ];
        }
    },
    computed: {
        ...mapGetters({
            username: 'user/USER_NAME',
            authCookie: 'user/AUTH_COOKIE',
            userGroups: 'user/USER_GROUPS',
            env: 'ENV',
            claims: 'user/USER_CLAIMS'
        })
    }
};
</script>

<style lang="scss" scoped>
.jira-dialog {
  :deep(.el-alert) {
    margin-bottom: 1em;
  }

  :deep(.el-dialog__body) {
    padding-top: 0;
  }
}
</style>
