/**
 * Vue mixin that provides validation functionality to vue objects.
 * Provides a place to put common validation code (checks for emptiness, range validations, etc), but mostly to make
 * it easier to set up validation configuration of both testing rules as well as messages, and a simple way to expose
 * validation tests that fail as error messages using some state properties that can also be used for highlighting
 * fields, etc.
 *
 * SETUP (do the vue mixin deal):
 *
 *     export default {
 *         mixins: [ValidatorMixin],
 *
 * CONFIG (define "validators()" function that returns an array of validations)
 * Note 1: one of the validators is a 'ref', that uses the 'ref' of a sub-component. the validation of the
 *         sub-component will happen in that position in the list
 * Note 2: the 'someOtherTest' has a 'depends' property, where it will not be run unless the tests that it depends on
 *         have all passed. For example, you may want a separate test/message to say that a field is required, but
 *         another test/message that describes that it has incorrect data... or to group dependent conditions, etc.
 *
 *        methods: {
 *            validators () {
 *                return [
 *                    { key: 'noReportName', message: 'Report Name is a required field', valid: () => !this.validateEmpty(this.state.reportName) },
 *                    { key: 'noQuery', message: 'SQL Query is a required field', valid: () => !this.validateEmpty(this.state.sqlQuery) },
 *                    { ref: 'reportScheduler' }
 *                    { key: 'someOtherTest', message: 'example screen message', valid: () => true, depends: ['noReportName', 'noQuery'] }
 *                ]
 *            },
 *
 */

/**
 * Actually executes a validation.
 *
 * @param comp - component object to have its validation run
 * @param messages - object to compile the key:message values of failed tests
 * @param components - to build an array of components encountered along the way (because they can nest)
 */
function executeValidation(comp, messages, components) {
    components.push(comp);
    comp.validators().forEach(v => {
        if (v.ref) {
            executeValidation(comp.$refs[v.ref], messages, components);
        } else if (!v.depends || !v.depends.length || v.depends.every(d => !messages[d])) {
            // no depends, or no results from invalid dependent tests
            try {
                if (!v.valid()) {
                    messages[v.key] = v.message;
                }
            } catch (e) {
                messages[v.key] = v.message;
            }
        }
    });
}

// export the Vue plugin installer
export default {
    methods: {
        /**
         * Runs the validations, and returns a true result if no error messages were triggered
         * @returns {boolean}
         */
        validated() {
            const result = {};
            const components = [];

            // run the validation on the current obj (will naturally call any configured children)
            executeValidation(this, result, components);

            // run the result through all the discovered components (all components get a chance to see all messages)
            const keys = Object.keys(result);
            components.forEach(c => c.processErrors(result, keys));
            this._validationComponents = components;

            // return a boolean result, true if things validated without errors
            return keys.length <= 0;
        },

        /**
         * This allows components to update their state with messages, so that the reactive code can wake up and do
         * things to the UI based on the presence of the messages.
         * @param errors - dictionary of error messages to their key properties
         * @param keys - a convenience array of the keys in the object (so that every component doesn't have to pull them)
         */
        processErrors(errors, keys) {
            // clear any previous runnings...
            if (this._validityErrorKeys) {
                this._validityErrorKeys.forEach(k => {
                    delete this.validity[k];
                });
            }

            // set new
            this._validityErrorKeys = keys;
            keys.forEach(k => { this.validity[k] = errors[k]; });
        },

        removeValidation(key) {
            this._validationComponents.forEach(c => delete c.validity[key]);
        },

        /**
         * Validation helper methods that will be attached to mixed in vue code.
         * Arguably could be moved to a util library (also arguably, this is a util library :)
         */
        validateInBounds(num, low, high) {
            if (num === null || num === undefined) return false;
            if (typeof num === 'string') {
                num = num.includes('.') ? parseFloat(num) : parseInt(num);
            }
            return (!isNaN(num) && num >= low && num <= high);
        },

        validateEmpty(s) {
            return (!s || ('' + s).trim().length <= 0);
        },

        validateNonZero(s) {
            if (!s || isNaN(s)) return false;
            return s > 0;
        }
    }
};
