<template>
    <footer>
        <p>
            <span class="copyright">Copyright &#169; {{ currentYear }} | </span> v.{{
                version
            }}
        </p>
        <div class="footer-logo"></div>
    </footer>
</template>

<script>
export default {
    computed: {
        currentYear: function () {
            return new Date().getFullYear();
        },
        version: function () {
            return '5.' + this.$store.state.system.version;
        }
    }
};
</script>
