<template>
    <div>
        <text-comp
            v-if="node.type === 'text'"
            :node="node"
            :load-state="loadState"
            :parent-key="parentKey"
            @changed="paramsChanged"
            @validatorBubble="validatorBubble"
        ></text-comp>

        <text-comp
            v-if="node.type === 'password'"
            :password="true"
            :node="node"
            :load-state="loadState"
            :parent-key="parentKey"
            @changed="paramsChanged"
            @validatorBubble="validatorBubble"
        ></text-comp>

        <group
            v-if="node.type === 'group'"
            :node="node"
            :load-state="loadState"
            :parent-key="parentKey"
            @changed="paramsChanged"
            @validatorBubble="validatorBubble"
        ></group>

        <date-picker
            v-if="node.type === 'date'"
            :node="node"
            :load-state="loadState"
            :parent-key="parentKey"
            @changed="paramsChanged"
            @validatorBubble="validatorBubble"
        ></date-picker>

        <date-range
            v-if="node.type === 'date-range'"
            :node="node"
            :load-state="loadState"
            :parent-key="parentKey"
            @changed="paramsChanged"
            @validatorBubble="validatorBubble"
        ></date-range>

        <select-comp
            v-if="node.type === 'select'"
            :node="node"
            :load-state="loadState"
            :parent-key="parentKey"
            @changed="paramsChanged"
            @validatorBubble="validatorBubble"
        ></select-comp>

        <select-advertiser
            v-if="node.type === 'select-advertiser'"
            :load-state="loadState"
            :parent-key="parentKey"
            :node="node"
            @changed="paramsChanged"
            @validatorBubble="validatorBubble"
        ></select-advertiser>

        <select-dsp
            v-if="node.type === 'select-dsp'"
            :load-state="loadState"
            :parent-key="parentKey"
            :node="node"
            @changed="paramsChanged"
            @validatorBubble="validatorBubble"
        ></select-dsp>

        <constant
            v-if="node.type === 'constant'"
            :load-state="loadState"
            :parent-key="parentKey"
            :node="node"
            @changed="paramsChanged"
            @validatorBubble="validatorBubble"
        ></constant>

        <checkbox
            v-if="node.type === 'checkbox'"
            :load-state="loadState"
            :parent-key="parentKey"
            :node="node"
            @changed="paramsChanged"
            @validatorBubble="validatorBubble"
        ></checkbox>

        <divider v-if="node.type === 'divider'"></divider>
    </div>
</template>

<script>
/**
 * This is mostly a pass-through delegator based on the type of the type of the current object.
 * To simplify recursion of sorts and the handling of type choice in other component types.
 */
import TextComp from './Text';
import Group from './Group';
import DateRange from './DateRange';
import DatePicker from './DatePicker';
import SelectComp from './Select';
import SelectAdvertiser from './SelectAdvertiser';
import SelectDsp from './SelectDSP';
import Constant from './Constant';
import Checkbox from './Checkbox';
import Divider from './Divider';

export default {
    props: ['node', 'parentKey', 'loadState'],
    components: {
        Checkbox,
        Group,
        TextComp,
        DateRange,
        DatePicker,
        SelectComp,
        SelectAdvertiser,
        SelectDsp,
        Constant,
        Divider
    },

    methods: {
        paramsChanged(dat) {
            this.$emit('changed', dat);
        },
        validatorBubble(dat) {
            this.$emit('validatorBubble', dat);
        }
    }
};
</script>
