import { atsApi } from '@/lib/axios';

const state = {
    dsps: []
};

const getters = {
    DSPS: store => store.dsps,
    DSP_OPTIONS: store => {
        const clone = store.dsps.slice();
        return clone.map(dsp => {
            return {
                label: dsp.NAME,
                value: dsp.ADVERTISER_ID,
                dsp: dsp.DSP_ID
            };
        });
    }
};

const mutations = {
    SET_DSPS(state, dsps) {
        state.dsps = dsps;
    }
};

const actions = {
    FETCH_DSPS: ({ commit }) => {
        return atsApi.get('/dsp')
            .then(response => {
                if (response.data && response.data.length) {
                    commit('SET_DSPS', response.data);
                }
            })
            .catch(err => {
                console.log('[Error] Error retrieving DSPs ...', err);
            });
    }
};

export default {
    namespaced: true, // auto namespace this module with its name
    state,
    getters,
    mutations,
    actions
};
