import {opticsApi} from '@/lib/axios';

const state = {
    sources: [],
    searchQueue: 0,
    processTypeLabels: {
        m1_raw: 'M1',
        segment_builder: 'DAN Tag Segment Builder',
        behavioral_segment_builder: 'Behavioral Segment Builder',
        dcm_activity: 'DCM activity',
        custom_segment: 'Manual Segment'
    }
};

const getters = {
    PROCESS_TYPE_LABELS: store => store.processTypeLabels,
    SEARCH_QUEUE: store => store.searchQueue,
    SOURCES: store => store.sources
};

const mutations = {
    SET_SOURCES(state, sources) {
        state.sources = sources;
    }
};

const actions = {
    FETCH_SOURCES: async ({commit}, params) => {
        const qsParams = [
            `pageOffset=${params.full ? 0 : state.sources.length}`,
            'pageSize=20',
            `sortColumn=${params.sortColumn}`,
            `sortDescending=${params.sortDescending}`
        ];
        let showMore = false;
        const cache = params.cache === undefined || params.cached === true; // default to true
        let sources = [];

        // add filters if available
        if (params.selectedAdvertiser) qsParams.push(`advertiserGroupId=${params.selectedAdvertiser.id}`);
        if (params.selectedSourceFilter) qsParams.push(`sourceFilter=${params.selectedSourceFilter}`);
        if (params.tableFilterInput) qsParams.push(`searchTerm=${params.tableFilterInput}`);

        // generate the query string
        const qs = qsParams.join('&');

        state.searchQueue++;

        try {
            let { data } = await opticsApi.get(`/audiences/sources/?${qs}`);
            state.searchQueue--;

            if (data) {
                if (params.full) {
                    sources = data.sources;
                } else {
                    sources = state.sources.length ? state.sources.concat(data.sources) : data.sources;
                }
                showMore = data.numReturned + data.offset < data.numItems;
            }

            if (cache) commit('SET_SOURCES', sources);

            return {showMore, sources};
        } catch (err) {
            console.error(err);
            return err;
        }
    }
};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
