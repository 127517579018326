<template>
    <div class="app-wrapper">
        <Top v-if="!framed" />
        <div class="main-wrapper" v-loading="spinner > 0" :class="{framed: framed}">
            <Side v-if="hasGroups && canDoThings() && !framed"></Side>
            <DentsuConnectSide v-else-if="framed" />
            <router-view :key="$route.fullPath" v-loading="spinner > 0"></router-view>
        </div>
        <Bottom v-if="!framed" :version="version"></Bottom>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Top from './components/Top';
import Bottom from './components/Bottom';
import Side from './components/Side';
import canDoThings from '@/mixins/canDoThings';
import DentsuConnectSide from "@/components/DentsuConnectSide.vue";

export default {
    mixins: [canDoThings],
    components: {
        DentsuConnectSide,
        Top,
        Side,
        Bottom
    },
    data() {
        return {
            authenticated: false,
            user: '',
            version: '?'
        };
    },
    computed: {
        ...mapGetters({
            hasGroups: 'user/HAS_GROUPS',
            spinner: 'SPINNER',
            framed: 'FRAMED'
        })
    },
    mounted() {
        console.log(`Framed: ${this.framed}`);
    }
};
</script>
