const Tag = () => import('@/components/optics/tags/Tag');
const Tags = () => import('@/components/optics/tags/Tags');
const AdvertiserManager = () => import('@/components/optics/advertisers/AdvertiserManager');
const AdvertiserManagerEdit = () => import('@/components/optics/advertisers/AdvertiserManagerEdit');
const SeedSegments = () => import('@/components/optics/seedSegmentBuilder/SeedSegments.vue');
const SeedSegment = () => import('@/components/optics/seedSegmentBuilder/SeedSegment.vue');

const AdvantageSegments = () => import('@/components/optics/audiences/AdvantageSegments.vue');
const AdvantageSegment = () => import('@/components/optics/audiences/AdvantageSegment.vue');

export const optics = [
    {
        path: '/tag-manager-create',
        component: Tag,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tag-manager-edit/:id',
        component: Tag,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/advantage-segment-builder/:id',
        name: 'audienceEdit',
        component: AdvantageSegment,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/advantage-segment-builder/create',
        name: 'audienceCreate',
        component: AdvantageSegment,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/advantage-segment-builder/',
        name: 'audienceList',
        component: AdvantageSegments,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tag-manager',
        component: Tags,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/advertiser-manager',
        name: 'advertiserList',
        component: AdvertiserManager,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/advertiser-manager/create',
        component: AdvertiserManagerEdit,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/advertiser-manager-edit/:id',
        component: AdvertiserManagerEdit,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/seed-segment-builder/',
        name: 'seedSegmentBuilderList',
        component: SeedSegments,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/seed-segment-builder/create',
        name: 'seedSegmentBuilderCreate',
        component: SeedSegment,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/seed-segment-builder/:id',
        name: 'seedSegmentBuilderEdit',
        props: (route) => ({ id: route.params.id, processType: route.query.process_type }),
        component: SeedSegment,
        meta: {
            requiresAuth: true
        }
    }
];
