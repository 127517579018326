<template>
    <el-row type="flex">
        <el-col :span="6" style="padding-right: 5px" class="top-search">
            <el-select v-model="typeSelected">
                <el-option
                    v-for="types in typeSelect"
                    :key="types"
                    :label="types"
                    :value="types"
                >
                </el-option>
            </el-select>
        </el-col>
        <el-col :span="18" class="top-search__input-wrapper">
            <list-filter-select
                v-model="selectedRecord"
                component-key="ui.search.topSearchInput"
                remote
                clearable
                filterable
                :remote-method="runSearch"
                :loading="loading"
                @change="resultSelected"
                placeholder="Search for segment ids..."
                class="top-search"
            >
                <el-option
                    v-for="(item, idx) in resultsList"
                    :label="item.name"
                    :key="'searchResult-' + idx"
                    :value="item"
                >
                    <span class="leftResult">{{ item.name }}
                        <span
                            v-if="item.typeName !== 'Report' && item.typeName !== 'Advertiser'"
                            class="leftSubInfo"
                        >, {{ item.advertiserName }}, {{ item.dspName }}</span>
                    </span>
                    <span class="rightResult">{{ item.typeName }}</span>
                </el-option>
            </list-filter-select>
        </el-col>
    </el-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { atsApi } from '@/lib/axios';
import ListFilterSelect from '@/components/shared/ListFilterSelect';
// thing
const urls = {
    searchTemp: '/search/topSearch'
};

export default {
    components: {
        ListFilterSelect
    },

    data() {
        return {
            selectedRecord: null,
            searchInputText: null,
            resultsList: null,
            loading: false,
            typeSelected: 'All',
            typeSelect: ['All', 'Segments', 'Reports', 'Advertiser']
        };
    },
    computed: {
        ...mapGetters({
            authenticated: 'user/IS_AUTHENTICATED',
            userGroups: 'user/USER_GROUPS'
        }),
        isUserAdmin() {
            return this.userGroups.includes('ats_usersBETA-app');
        }
    },
    methods: {
        runSearch(query) {
            this.resultsList = null;
            this.searchedTermInput = query;
            if (query && query.trim().length > 0) {
                this.loading = true;
                atsApi
                    .post(urls.searchTemp, {
                        searchTerm: query.trim(),
                        type: this.typeSelected
                    })
                    .then((response) => {
                        this.loading = false;
                        if (
                            response.data &&
                            response.data.results &&
                            response.data.results.length > 0
                        ) {
                            const uniques = {};
                            const results = response.data.results;

                            results.sort((a, b) =>
                                a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                            );

                            this.resultsList = results
                                .map((r) => {
                                    const result = { ...r };
                                    switch (r.type) {
                                        case 'report': {
                                            result.link = '/reports/edit/' + r.id;
                                            result.typeName = 'Report';
                                            break;
                                        }
                                        case 'dantag': {
                                            result.link = '/seed-segment-builder/' +
                                            r.id +
                                            '?process_type=' +
                                            r.type;
                                            result.typeName = 'Dan Tag Segment';
                                            break;
                                        }
                                        case 'behavioral_segment_builder': {
                                            result.link =
                                            '/seed-segment-builder/' +
                                            r.id +
                                            '?process_type=' +
                                            r.type;
                                            result.typeName = 'Behavioral Segment';
                                            break;
                                        }
                                        case 'audience_builder': {
                                            result.link = '/advantage-segment-builder/' + r.id;
                                            result.typeName = 'Advantage Segment';
                                            break;
                                        }
                                        case 'segment_builder': {
                                            result.link =
                                            '/seed-segment-builder/' +
                                            r.id +
                                            '?process_type=' +
                                            r.type;
                                            result.typeName = 'Dan Tag Segment';
                                            break;
                                        }
                                        case 'contextual_intelligence': {
                                            result.link = '/contextual/' +
                                            r.contextId +
                                            '?process_type' +
                                            r.type;
                                            result.typeName = 'Contextual Intelligence Segment';
                                            break;
                                        }
                                        case 'advertiser': {
                                            result.link = '/advertiser-manager-edit/' + r.id;
                                            result.typeName = 'Advertiser';
                                        }
                                    }
                                    return result;
                                })
                                .filter((r) => {
                                    if (!r.link) return false;
                                    if (!uniques[r.link]) {
                                        uniques[r.link] = true;
                                        return true;
                                    }
                                    return false;
                                });
                        } else {
                            this.resultsList = null;
                        }
                    })
                    .catch(() => {
                        this.resultsList = null;
                    });
            }
        },

        resultSelected(result) {
            if (result) {
                if (result.link.startsWith('http')) {
                    window.open(result.link, '_blank');
                } else {
                    this.$router.push({ path: result.link });
                }
            }
            this.selectedRecord = this.searchedTermInput;
        }
    }
};
</script>

<style lang="scss" scoped>
.leftResult {
  float: left;
}

.leftSubInfo {
  font-weight: normal;
  color: #8492a6;
}

.rightResult {
  float: right;
  color: #8492a6;
  font-size: 13px;
}

:deep(.el-input__inner) {
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #fff;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff;
  }
  &::placeholder {
    /* Most modern browsers support this now. */
    color: #fff;
  }
}

:deep(.top-search) {
    .el-input__wrapper  {
        background-color: #37374b !important;
        border: none !important;
        box-shadow: none !important;
    }

    //&__input-wrapper {
    //    flex: 1 1 auto;
    //}
}
</style>
