import { atsApi } from '@/lib/axios';

const state = {
    countries: [],
    regions: [],
    dmas: [],
    cities: []
};

const getters = {
    COUNTRIES: store => store.countries,
    REGIONS: store => store.regions,
    DMAS: store => store.dmas,
    CITIES: store => store.cities
};

const mutations = {
    REGISTER_COUNTRIES(state, countries) {
        state.countries = countries;
    },
    REGISTER_REGIONS(state, regions) {
        state.regions = regions;
    },
    REGISTER_DMAS(state, dmas) {
        state.dmas = dmas;
    },
    REGISTER_CITIES(state, cities) {
        state.cities = cities;
    }
};

const actions = {
    FETCH_COUNTRIES: async ({ commit }) => {
        try {
            const { data } = await atsApi.get('/geo/v2?fields=country');
            if (data) {
                commit('REGISTER_COUNTRIES', data.results.country);
                return data.results.country;
            }
        } catch (e) {
            throw new Error(`Error fetching countries. ${e}`);
        }
    },

    FETCH_REGIONS: async ({ commit }, country) => {
        try {
            const { data } = await atsApi.get(`/geo/v2?fields=region&countryFilter=${country}&countryFilterTargets=region`);
            if (data) {
                commit('REGISTER_REGIONS', data.results.region);
                return data.results.region;
            }
        } catch (e) {
            throw new Error(`Error fetching regions. ${e}`);
        }
    },

    FETCH_DMAS: async ({ commit }, country) => {
        try {
            const { data } = await atsApi.get(`/geo/v2?fields=dma&countryFilter=${country}&countryFilterTargets=dma`);
            if (data) {
                commit('REGISTER_DMAS', data.results.dma);
                return data.results.dma;
            }
        } catch (e) {
            throw new Error(`Error fetching dmas. ${e}`);
        }
    },

    FETCH_CITIES: async ({ commit }, config) => {
        try {
            const { data } = await atsApi.get(`/geo/v2?fields=city&countryFilter=${config.country}&countryFilterTargets=city&regionFilter=${config.region}&regionFilterTargets=city`);
            if (data) {
                commit('REGISTER_CITIES', data.results.city);
                return data.results.city;
            }
        } catch (e) {
            throw new Error(`Error fetching cities. ${e}`);
        }
    },

    SEARCH_CITIES: async (_, config) => {
        try {
            const { data } = await atsApi.get(`/geo/v2?item=city&values=${config.cities}${config.countryFilter ? '&countryFilter=' + config.countryFilter : ''}${config.regionCodeFilter ? '&regionCodeFilter=' + config.regionCodeFilter : ''}`);
            if (data) {
                return data.results;
            }
        } catch (e) {
            throw new Error(`Error searching cities. ${e}`);
        }
    }
};

export default {
    namespaced: true, // auto namespace this module with its name
    state,
    getters,
    mutations,
    actions
};
