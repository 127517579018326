export const CONTENT_TYPE_VIDEO = 'video';
export const CONTENT_TYPE_WEB = 'web';
export const MSG_SEGMENT_CREATED = 'Segment has been created.';
export const MSG_SEGMENT_UPDATED = 'Segment has been updated.';
export const MSG_SEGMENT_PUBLISHED_WEB = 'Segment has been published. Please activate segment in the segment listing page.';
export const MSG_SEGMENT_PUBLISHED_VIDEO = 'Segment has been submitted. Please allow 48 business hours for it to publish & activate segment in the segment listing page.';
export const MSG_SEGMENT_CREATE_ERROR = 'Error saving segment.';
export const MSG_SEGMENT_UPDATE_ERROR = 'Error updating segment.';
export const MSG_SEGMENT_PUBLISH_ERROR = 'There was an error publishing your segment.';
export const MSG_SEGMENT_EXPORT_MISSING_CRITERIA_ERROR = 'Segment must be created before performing this operation.';
export const SENTIMENT_VALUE_POSITIVE = 'positive';
export const SENTIMENT_VALUE_NEGATIVE = 'negative';
export const SENTIMENT_VALUE_NEUTRAL = 'neutral';
export const VIDEO_URL_CATEGORIES = 'videoURLCategories';
export const THREAT_CATEGORIES = 'threat_iab_v1';
export const IAB_CATEGORIES = 'iab_v3';
export const GUMGUM = 'gumgum';
export const LANG_ENG = 'english';
export const SLOP_DEFAULT_VALUE = 2;

// localStorage is used for storing and retrieving client-side data
// and the data in localStorage doesn't expire.
export const LOCAL_USER_PROFILE = 'user.profile';
export const LOCAL_USER_GROUPS = 'user.groups';
export const LOCAL_SIDE_BAR_VISIBLE = 'sideBarVisible';
export const LOCAL_TARGETS = 'targets';
export const LOCAL_INFO_DIALOG_VISIBLE = 'infoDialogVisible';
export const LOCAL_PREV_ROUTE = 'dentsuConnect.prevRoute;';
export const LOCAL_SELECTED_ADVERTISER = 'ui.ContextualList.selectAdvertiser';
export const LOCAL_SEARCH_QUERY = 'ui.ContextualList.searchQuery';
export const LOCAL_CONTEXTUAL_LIST_SORT_BY = 'ui.ContextualList.sortBy';
export const LOCAL_CONTEXTUAL_LIST_SORT_ORDER = 'ui.ContextualList.sortOrder';
export const LOCAL_CONTEXTUAL_LIST_DISPLAY_COUNT = 'ui.ContextualList.displayCount';
export const LOCAL_CONTEXTUAL_LIST_SHOW_MORE = 'ui.ContextualList.isShowMore';
