export default {
    loadTargets: function (context, data) {
        context.commit({
            type: 'loadTargets',
            targets: data.targets
        });
    },
    loadCachedAdvertiser: function (context, data) {
        context.commit({
            type: 'loadCachedAdvertiser',
            adv: data.adv
        });
    },
    loadCachedIO: function (context, data) {
        context.commit({
            type: 'loadCachedIO',
            io: data.io
        });
    },
    updateIO: function (context, data) {
        context.commit({
            type: 'updateIO',
            selection: data.msg.selection,
            isWatched: data.msg.isWatched
        });
    },
    updateAdvertiser: function (context, data) {
        context.commit({
            type: 'updateAdvertiser',
            advertiser: data.advertiser
        });
    },
    updateTargets: function (context, data) {
        context.commit({
            type: 'updateTargets',
            target: data.target
        });
    }
};
