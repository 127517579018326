<template>
    <el-row :gutter="20">
        <el-col :span="4">
            <label>Date Range</label>
            <el-select
                v-model="state.rangeSelect"
                placeholder="Select"
                clearable
                @change="paramsChanged"
            >
                <el-option
                    v-for="option in rangeOptions"
                    :key="'range_' + option.value"
                    :label="option.label"
                    :value="option.value"
                >
                </el-option>
            </el-select>
        </el-col>
        <el-col v-if="state.rangeSelect === 'custom'" :span="8">
            <label>From:</label>
            <el-date-picker
                v-model="state.from"
                type="date"
                :clear-icon="ElIconCircleCloseOutline"
                @change="paramsChanged"
            >
            </el-date-picker>
        </el-col>
        <el-col v-if="state.rangeSelect === 'custom'" :span="8">
            <label>To:</label>
            <el-date-picker
                v-model="state.to"
                type="date"
                :clear-icon="ElIconCircleCloseOutline"
                @change="paramsChanged"
            >
            </el-date-picker>
        </el-col>
    </el-row>
</template>

<script>
import { CircleClose as ElIconCircleCloseOutline } from '@element-plus/icons-vue';
import moment from 'moment-timezone';
import { shallowRef } from 'vue';

export default {
    data() {
        return {
            state: {
                rangeSelect: null,
                fullKey: null,
                from: null,
                to: null
            },
            rangeOptions: [
                { value: '-1,days', label: 'Yesterday' },
                { value: '-7,days', label: 'Last 7 days' },
                { value: '-30,days', label: 'Last 30 days' },
                { value: '-90,days', label: 'Last 90 days' },
                { value: 'custom', label: 'Custom date range...' }
            ],
            ElIconCircleCloseOutline: shallowRef(ElIconCircleCloseOutline)
        };
    },
    props: ['node', 'parentKey', 'loadState'],
    mounted() {
        this.state.fullKey = [this.parentKey, this.node.key]
            .map((p) => (p || '').trim()) // remove nulls, trim results
            .filter((p) => !!p.length)
            .join('.'); // filter zero length
        if (this.loadState) {
            const obj = this.loadState[this.state.fullKey];
            if (obj) {
                if (obj._typeRelativeDateRange) {
                    this.state.rangeSelect = obj._typeRelativeDateRange;
                } else {
                    ['from', 'to']
                        .filter((p) => !!obj[p])
                        .forEach(
                            (p) => (this.state[p] = moment(obj[p].replace(/'/g, '')).utc())
                        );
                    this.state.rangeSelect = 'custom';
                }
            }
        }

        if (this.node.default && !this.state.from && !this.state.to) {
            ['from', 'to'].forEach((k) => {
                let def = this.node.default[k];
                let unit = 'days';
                let amount = 0;

                // a few convenience things
                if (def === 'today') amount = 0;
                else if (def === 'yesterday') amount = -1;
                else if (def === 'tomorrow') amount = 1;
                else {
                    // parse out units and amount
                    def = def.split(',').map((v) => v.trim());
                    amount = parseInt(def[0]);
                    unit = def[1];
                }

                this.state[k] = moment().utc().add(amount, unit);
            });
            // emit the state to parents
            this.paramsChanged();
        }
    },
    methods: {
        paramsChanged() {
            if (this.state.fullKey.length > 0) {
                if (this.state.rangeSelect === 'custom') {
                    // custom range gets 'from' and 'to' properties
                    this.$emit('changed', {
                        [this.state.fullKey]: {
                            from: this.state.from
                                ? moment(this.state.from).format('YYYY-MM-DD')
                                : null,
                            to: this.state.to
                                ? moment(this.state.to).format('YYYY-MM-DD')
                                : null
                        }
                    });
                } else if (this.state.rangeSelect) {
                    // dynamic range gets a value that will be evaluated at query generation point
                    this.$emit('changed', {
                        [this.state.fullKey]: {
                            _typeRelativeDateRange: this.state.rangeSelect
                        }
                    });
                }
            } else {
                console.log(
                    'value just changed in a dateRange component but there was no key?'
                );
            }
        }
    }
};
</script>
