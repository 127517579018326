import ls from 'local-storage';

const state = {
    ui: {
        selectedKeyword: null,
        selectedAdvertiser: null
    }
};

const getters = {};

const mutations = {
    SET_SELECTED_KEYWORD(state, keyword) {
        const cache = ls.get('ui.connect');
        let connectCache = null;
        state.ui.selectedKeyword = keyword;
        if (cache) {
            connectCache = cache;
            if (connectCache.campaigns) {
                connectCache.campaigns.selectedKeyword = keyword;
            } else {
                connectCache.campaigns = {
                    selectedKeyword: keyword
                };
            }
        } else {
            connectCache = {
                campaigns: {
                    selectedKeyword: keyword
                }
            };
        }
        ls.set('ui.connect', connectCache);
    },
    SET_SELECTED_ADVERTISER(state, advertiser) {
        state.ui.selectedAdvertiser = advertiser;
    }
};

const actions = {};

export default {
    namespaced: true, // auto namespace this module with its name
    state,
    getters,
    mutations,
    actions
};
