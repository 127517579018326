<template>
    <div :class="['sidebar-container', { collapsed: !sidebarOpen }]">
        <el-button @click="toggleSidebar" class="sidebar-toggle-btn">
            {{ sidebarOpen ? '<<' : '>>' }}
        </el-button>
        <el-menu
            :collapse="!sidebarOpen"
            class="el-menu-vertical"
            background-color="#f9f9f9"
            text-color="#303133"
        >
            <el-menu-item-group>
                <el-menu-item>
                    <template #title>
                        <a
                            class="section-title"
                            href="https://name-gen.wal.int.az.eu.mediaecosystem.io/"
                            target="_blank"
                        >
                            Home</a>
                    </template>
                </el-menu-item>
            </el-menu-item-group>

            <el-menu-item-group>
                <template #title>
                    <span class="section-title">Configure</span>
                </template>
                <el-menu-item v-for="(route, index) in configureRoutes" :key="index" :index="'1-' + index">
                    <a :href="route.route" target="_blank">{{ route.label }}</a>
                </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
                <template #title>
                    <span class="section-title">Manage</span>
                </template>
                <el-menu-item v-for="(route, index) in manageRoutes" :key="index" :index="'2-' + index">
                    <a :href="route.route" target="_blank">{{ route.label }}</a>
                </el-menu-item>
            </el-menu-item-group>
        </el-menu>
    </div>
</template>

<script setup>
import {ref} from 'vue';

const sidebarOpen = ref(true);

const toggleSidebar = () => {
    sidebarOpen.value = !sidebarOpen.value;
};

const configureRoutes = [
    {label: 'Metadata', route: 'https://name-gen.wal.int.az.eu.mediaecosystem.io/taxonomy/campaignfolder'},
    {label: 'Supply', route: 'https://do-frontend-dot-iprospect-global-data-lake.uc.r.appspot.com/inventory-list'},
    {label: 'Deal', route: 'https://do-frontend-dot-iprospect-global-data-lake.uc.r.appspot.com/marketplace'},
    {label: 'Contextual', route: 'https://name-gen.wal.int.az.eu.mediaecosystem.io/'}
];

const manageRoutes = [
    {label: 'Copy', route: 'https://dc-dscriptor.wal.demo.az.eu.mediaecosystem.io/?activation=true'},
    {label: 'Performance', route: 'https://do-frontend-dot-iprospect-global-data-lake.uc.r.appspot.com/'},
    {label: 'Delivery', route: 'https://staging-dentsu-connect-zg46lnlgpq-nw.a.run.app/'}
];
</script>

<style scoped lang="scss">
.sidebar-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  transition: width 0.3s ease;

  &.collapsed {
    width: 60px;
  }

  &:not(.collapsed) {
    width: 220px;
  }
}

.el-menu-vertical:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}

.el-menu-vertical.el-menu--collapse .el-menu-item a {
  display: none;
}

.el-menu-vertical:not(.el-menu--collapse) .el-menu-item a {
  display: block; /* Show the text in expanded state */
}

.el-menu-vertical.el-menu--collapse .section-title {
  display: none; /* Hide section titles in collapsed state */
}

.el-menu-vertical .el-menu-item-group:not(:first-of-type)::before {
  content: '';
  display: block;
  height: 2px;
  background-color: var(--dentsu-color-n300);
  margin: 10px 20px;
}


.sidebar-toggle-btn {
  margin: 10px;
  background-color: #fff;
  color: #303133;
  border: none;
  box-shadow: none;

  &:hover {
    background-color: #fff;
    color: #303133;
    box-shadow: none;
  }

  &:active {
    background-color: #fff;
    color: #303133;
    box-shadow: none;
  }

  &:focus {
    outline: none;
  }
}


.section-title {
  font-size: 14px;
  font-weight: bold;
  color: #909399;
  text-transform: uppercase;
}

.el-menu-item {
  padding-left: 20px;
}

.el-menu-item a {
  color: #303133;
  text-decoration: none;
  font-size: 14px;
}

.el-menu-item a:hover {
  color: #409eff;
}
</style>
