// noinspection JSUnresolvedVariable
import { createStore } from 'vuex';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import advertiser from './modules/advertiser';
import alert from './modules/alert';
import dsp from './modules/dsp';
import campaign from './modules/campaign';
import geo from './modules/geo';
import lineItem from './modules/lineItem';
import sourceSegments from './modules/sourceSegments';
import tag from './modules/tag';
import user from './modules/user';

export default createStore({
    modules: {
        advertiser,
        alert,
        dsp,
        campaign,
        geo,
        lineItem,
        sourceSegments,
        tag,
        user
    },
    state: {
        sidebarVisible: true,
        spinner: 0,
        system: {
            env: (window.location.hostname === 'dentsuactivation.com') ? 'prod' : 'dev',
            framed: window.top !== window.self,
            // eslint-disable-next-line no-undef
            version: VERSION // VERSION is exposed via webpack. VERSION === package.json version
        },
        menus: {
            selectedAdvertiser: { label: 'All Advertisers', value: -1 },
            selectedAdvertisers: [],
            selectedIO: {
                insertion_order_id: null,
                insertion_order_name: null,
                advertiser_id: null,
                advertiser_name: null
            },
            date: {
                start: '',
                end: ''
            },
            isWatched: false,
            error: {
                state: false,
                msg: ''
            }
        },
        charts: {
            created: null,
            pacing: [],
            performance: [],
            targets: {}
        }
    },
    getters,
    mutations,
    actions
});
