<script>
import {ElSelect as Select} from 'element-plus';
import ls from 'local-storage';

export default {
    extends: Select,
    setup: Select.setup,
    name: 'ListFilterSelect',
    props: ['componentKey'],
    emits: ['update:modelValue'],
    watch: {
        modelValue: function (v) {
            this.setCache(v);
            this.emitter.on('update:modelVaLue', v);
        }
    },
    created() {
    },
    beforeMount() {
        try {
            if (this.componentKey) {
                const cache = ls.get(this.componentKey);
                if (cache) this.emitter.emit('input', cache);
            }
        } catch (e) {
            console.warn('error getting cache', e);
        }
    },
    mounted() {
        this.emitter.on('change', this.setCache);
    },
    methods: {
        setCache(v) {
            ls.set(this.componentKey, v);
        }
    }
};
</script>

<style scoped>
</style>
