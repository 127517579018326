import axios from 'axios';
import store from '@/store';

const productionUrlRegex = /^(insights|optics|connect)?\.?dentsuactivation\.com$/;
const marvelRegex = /^marvel-api\.dentsuactivation\.com$/;
const stage = productionUrlRegex.test(window.location.hostname) ? 'prod' : 'dev';
const isMarvel = (url) => marvelRegex.test(url);
/**
 * Wraps an instance of the axios client and sets standard headers into the request
 */
const wrapAxios = (url) => {

    // this makes an axios and pulls in the current header values
    const spawn = (args) => {
        const appBlock = {};

        const userId = store.getters['user/USER_ID'];
        if (userId) {
            appBlock.userId = userId;
        }

        const profile = store.getters['user/USER_PROFILE'];
        if (profile) {
            appBlock.profile = profile;
        }

        const groups = store.getters['user/USER_GROUPS'];
        if (groups) {
            appBlock.userGroups = groups;
        }

        // per-browser-tab id sounds handy
        if (!window.atsUserTabId) {
            window.atsUserTabId = (userId || '') + '_' + Math.random().toString(36).substring(2, 15) + '-' + (new Date().getTime());
        }
        appBlock.atsUserTabId = window.atsUserTabId;

        // intercept routing fun for development lambdas
        if (stage === 'dev') {
            // frank's block ...
            const alias = location.host.startsWith('connect') ? 'FSSTAGING' : 'FSDEV';

            if (args[0].startsWith('/audienceExplorer/')) {
                appBlock.lambdaRouting = { audienceExplorer: 'FSDEV' };
            }
            if (args[0].startsWith('/audiences')) {
                console.log('going to audiences ...', alias);
                appBlock.lambdaRouting = { audienceBuilder: alias };
            }
            if (args[0].startsWith('/seed-segment-builder')) {
                console.log('going to seeds ...', alias);
                appBlock.lambdaRouting = { seedBuilder: alias };
            }
            if (args[0].startsWith('/userAdmin/externalLogin')) {
                console.log('going to user admin ...', alias);
                appBlock.lambdaRouting = { userAdmin: 'AZDEV', runner: 'externalLogin' };
            }

            // dave's block ...
            // console.log(args[0]);
            // const alias = location.host.startsWith('optics') ? 'DHSTAGING' : 'DHDEV';

            // if (args[0].startsWith('/audiences')) {
            //     console.log('going to DH audiences ...', alias);
            //     appBlock.lambdaRouting = { audienceBuilder: alias };
            // }

            // if (args[0].startsWith('/seed-segment-builder')) {
            //     console.log('going to DH seeds ...', alias);
            //     appBlock.lambdaRouting = { seedBuilder: alias };
            // }

            // KMR BLOCK
            // if (args[0].startsWith('/weather-tool')) {
            //     console.log('using KRDEV alias');
            //     appBlock.lambdaRouting = { weatherTool: "KRDEV" };
            // }

            // arron's block...
            /*
            if (args[0].startsWith('/audienceExplorer/')) {
                // for new handlers that exist in no other version, need to explicitly set the runner
                if (args[0] === '/audienceExplorer/brandNewHandler') {
                    appBlock.lambdaRouting = { audienceExplorer: { alias: 'AZDEV', runner: 'brandNewHandler' } };
                } else {
                    // all others will be found and work properly
                    appBlock.lambdaRouting = { audienceExplorer: 'AZDEV' };
                }
            }
            */
        }
        const authKey = store.getters['user/OKTA_TOKEN'];

        const headers = {
            'X-ats-application': JSON.stringify(appBlock),
            'Authorization': 'Bearer ' + authKey
        };

        if (isMarvel(url)) headers.Authorization = 'Basic YmlhdTd1N3lxcTo3czN5dXg2M2xu';

        return axios.create({
            baseURL: `https://${url}/${isMarvel(url) ? 'api' : stage}/`,
            headers
        });
    };

    // reference to set the 'this' on the apply() call
    let tmp;

    return {
        request: (...args) => (tmp = spawn(args)).request.apply(tmp, args),
        get: (...args) => (tmp = spawn(args)).get.apply(tmp, args),
        delete: (...args) => (tmp = spawn(args)).delete.apply(tmp, args),
        head: (...args) => (tmp = spawn(args)).head.apply(tmp, args),
        post: (...args) => (tmp = spawn(args)).post.apply(tmp, args),
        put: (...args) => (tmp = spawn(args)).put.apply(tmp, args),
        patch: (...args) => (tmp = spawn(args)).patch.apply(tmp, args)
    };
};

export const functionsApi = wrapAxios('b94x8sm19b.execute-api.us-east-1.amazonaws.com');

export const atsApi = wrapAxios('jhzu0yfsl6.execute-api.us-east-1.amazonaws.com');

export const connectApi = wrapAxios('g2x5y2do4i.execute-api.us-east-1.amazonaws.com');

export const opticsApi = wrapAxios('1ttxcuq4i7.execute-api.us-east-1.amazonaws.com');

export const insightsApi = wrapAxios('aqhvyctklh.execute-api.us-east-1.amazonaws.com');

export const adminApi = wrapAxios('i02dz9v0m6.execute-api.us-east-1.amazonaws.com');

export const marvelApi = wrapAxios('marvel-api.dentsuactivation.com');
