import AppInstance from './app';
import router from './router';
import store from './store';
import AppInstanceShortkey from 'vue-three-shortkey';
import TemplateNode from '@/components/insights/reports/templates/TemplateNode';
import { createBridge } from '@gdo-global-client-reporting/dentsu-platform-bridge';
import './plugins/element.js';
import emitter from './plugins/emitter.js';
import './styles/ats-shared.scss';


AppInstance.config.productionTip = false;
AppInstance.config.errorHandler = async (err, vm, info) => {
    // handle error
    console.log('info: ' + info);
    console.warn('Error caught in main.js', { err, info });
    if (router.currentRoute.value.name !== '/error') {
        await router.push({ name: 'error', params: { msg: JSON.stringify(err) } });
    }
};



createBridge({
    localeChangeHandler: () => {
        // TODO: implement UI logic to respond to locale change requests.
    }, // change language handler and switch language based on Platform Client selection
    parentOrigin: 'https://platform.wal.int.az.eu.mediaecosystem.io', // [Required] The hostname for the current app. To keep the apps secure, we are required to lock all communications to the app origin.
    isBypassSecurity: true, // temporarily bypass security, useful on dev environment e.g. localhost
    debug: false // trigger console debug messages
});

AppInstance.config.globalProperties.emitter = emitter;
AppInstance.component('TemplateNode', TemplateNode);
AppInstance.use(AppInstanceShortkey).use(router).use(store);
AppInstance.mount('#app');
