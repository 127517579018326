import ls from 'local-storage';
import {atsApi} from '@/lib/axios';

const state = {
    advertisers: [],
    advertiserGroups: [],
    advertiserGroupsActive: [],
    selectedAdvertiser: {}
};

const getters = {
    ADVERTISERS: store => store.advertisers,
    ADVERTISER_GROUPS: store => store.advertiserGroups,
    ADVERTISER_OPTIONS: store => {
        const clone = store.advertisers.slice();
        return clone.map(adv => {
            return {
                label: adv.NAME,
                value: adv.ADVERTISER_ID,
                dsp: adv.DSP_ID
            };
        });
    },
    ADVERTISERS_GROUPED: (store) => {
        const map = {};
        return store.advertisers
            .filter(adv => [1, 2, 3, 6, 8, 14, 15].indexOf(adv.DSP_ID) > -1)
            .map(adv => {
                return { label: adv.NAME, value: adv.DSP_ADVERTISER_NAME, dspId: adv.DSP_ID, advertiserId: adv.ADVERTISER_ID };
            })
            .reduce((grouped, currVal) => {
                if (map[currVal.label] === undefined) {
                    const entry = {
                        label: currVal.label,
                        value: [currVal]
                    };
                    map[currVal.label] = grouped.push(entry) - 1;
                } else {
                    grouped[map[currVal.label]].value.push(currVal);
                }
                return grouped;
            }, []);
    },
    // aggregate dsp advertisers under their groups, excluding groups with NULL ids
    ADVERTISER_GROUPS_ACTIVE: (store) => {
        const notNulls = store.advertiserGroupsActive.filter(g => g.GROUP_ID !== null);
        const aggObj = notNulls.reduce((acc, curr) => {
            if (Object.prototype.hasOwnProperty.call(acc, curr.GROUP_ID)) {
                acc[curr.GROUP_ID].dsps.push(curr.DSP_ID);
            } else {
                acc[curr.GROUP_ID] = {
                    id: curr.GROUP_ID,
                    advertiserId: curr.ADVERTISER_ID,
                    name: curr.GROUP_NAME,
                    dsps: [curr.DSP_ID]
                };
            }
            return acc;
        }, {});

        return Object.values(aggObj).sort((a, b) => {
            const c = a.name.toLowerCase();
            const d = b.name.toLowerCase();
            return (c < d) ? -1 : (c > d) ? 1 : 0;
        });
    },
    SELECTED_ADVERTISER: store => store.selectedAdvertiser,
    SELECTED_ADVERTISER_ID: store => store.selectedAdvertiser &&
        store.selectedAdvertiser.ADVERTISER_ID
};

const mutations = {
    SET_ADVERTISERS(state, advertisers) {
        state.advertisers = advertisers;
    },
    SET_ADVERTISER_GROUPS(state, groups) {
        state.advertiserGroups = groups;
    },
    SET_ADVERTISER_GROUPS_ACTIVE(state, groups) {
        state.advertiserGroupsActive = groups;
    },
    SET_SELECTED_ADVERTISER(state, advertiser) {
        ls.set('selectedAdvertiser', advertiser);
        state.selectedAdvertiser = advertiser;
    }
};

const actions = {
    FETCH_ADVERTISERS: ({ commit, rootGetters }) => {
        return new Promise((resolve, reject) => {
            if (!rootGetters['user/USER_GROUPS'].length &&
                !rootGetters['user/USER_PROFILE'].advertiserGroup) {
                reject(new Error('No user groups'));
            }
            const payload = {};
            if (rootGetters['user/USER_PROFILE'].advertiserGroup) {
                payload.advertiserGroup = rootGetters['user/USER_PROFILE'].advertiserGroup;
            } else if (rootGetters['user/USER_GROUPS']) {
                payload.groups = rootGetters['user/USER_GROUPS'];
            }
            atsApi.post('/advertiser-manager/advertisers-by-user-group', payload).then(resp => {
                commit('SET_ADVERTISERS', JSON.parse(resp.data).advertisers);
                resolve(resp.data);
            }).catch(err => {
                console.warn('[Error] ' + JSON.stringify(err));
                reject(err);
            });
        });
    },
    FETCH_ADVERTISER_GROUPS: async ({ commit, rootGetters }) => {
        try {
            if (!rootGetters['user/USER_GROUPS'].length) {
                throw new Error('No user groups');
            }
            const resp = await atsApi.get('/advertiser-manager/advertiser-groups', {
                params: {
                    groups: rootGetters['user/USER_GROUPS'].join(',')
                }
            });
            commit('SET_ADVERTISER_GROUPS', resp.data);
            return resp;
        } catch (e) {
            throw new Error(`Error encountered while fetching advertiser groups. ${e}`);
        }
    },
    FETCH_ADVERTISER_GROUPS_ACTIVE: async ({ commit, rootGetters }) => {
        console.log('fetching active advertiser groups');
        try {
            if (!rootGetters['user/USER_GROUPS'].length) {
                throw new Error('No user groups');
            }
            const resp = await atsApi.get('/advertiser-manager/advertiser-groups?filterInactiveAdvertisers=true');
            commit('SET_ADVERTISER_GROUPS_ACTIVE', resp.data);
            return resp;
        } catch (e) {
            throw new Error(`Error encountered while fetching advertiser groups. ${e}`);
        }
    },
    POST_ADVERTISER_GROUP: async (_, payload) => {
        try {
            return await atsApi.post('/advertiser-manager/create-advertiser-group', payload);
        } catch (e) {
            throw new Error(`Error encountered while updating advertiser group. ${e}`);
        }
    },
};

export default {
    namespaced: true, // auto namespace this module with its name
    state,
    getters,
    mutations,
    actions
};
