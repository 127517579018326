/**
 * Routes for the INSIGHTS pillar
 */
const ReportsList = () => import('@/components/insights/reports/ReportsList');
const ReportCreateFrame = () => import('@/components/insights/reports/QueryBuildFrame');

export const insights = [
    {
        path: '/reports',
        component: ReportsList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reports/list',
        component: ReportsList,
        name: 'reportsList',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reports/create',
        name: 'reportsCreate',
        component: ReportCreateFrame,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reports/:createEdit/:reportId/:subView*',
        name: 'reportsEdit',
        component: ReportCreateFrame,
        meta: {
            requiresAuth: true
        }
    }
];
