<template>
    <div>
        <el-row>
            <el-col>
                <label>Advertiser</label>
                <el-select
                    v-model="state.selected"
                    clearable
                    filterable
                    :multiple="state.multiple"
                    placeholder="Select Advertiser"
                >
                    <el-option
                        v-for="item in filteredAdvertisers"
                        :label="item.label"
                        :key="item.value"
                        :value="item"
                    >
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: ['node', 'parentKey', 'loadState'],

    data() {
        return {
            state: {
                fullKey: null,

                multiple: !!this.node.multiple,
                selected: [],
                selectedIds: []
            }
        };
    },

    computed: {
        ...mapState('advertiser', ['advertisers']),
        filteredAdvertisers: function () {
            return this.advertisers
                .filter((adv) => [1, 8, 15].includes(adv.DSP_ID))
                .map((adv) => ({
                    label: adv.NAME,
                    value: adv.ADVERTISER_ID,
                    dsp: adv.DSP_ID
                }));
        }
    },

    watch: {
    // state from advertisers can load later depending on how page is loaded
    // need to watch in order to set the advertiser
        advertisers() {
            this.setAdvertisers();
        },

        'state.selected': {
            handler(change) {
                this.selectionChanged(change);
            }
        }
    },

    mounted() {
        this.state.fullKey = [this.parentKey, this.node.key]
            .map((p) => (p || '').trim()) // remove nulls, trim results
            .filter((p) => !!p.length)
            .join('.'); // filter zero length

        if (this.filteredAdvertisers.length > 0) {
            this.setAdvertisers();
        }
    },

    methods: {
        setAdvertisers() {
            if (this.loadState) {
                let raw = this.loadState[this.state.fullKey];
                if (raw) {
                    raw = Array.isArray(raw) ? raw : [raw];

                    this.state.selectedIds = raw;

                    if (this.node.multiple) {
                        this.state.selected = this.filteredAdvertisers.filter((a) =>
                            raw.includes(a.value + '')
                        );
                    } else {
                        const tmp = this.filteredAdvertisers.filter((a) =>
                            raw.includes(a.value + '')
                        );
                        this.state.selected = tmp[0];
                    }
                }
            }
        },

        selectionChanged(ads = []) {
            ads = Array.isArray(ads) ? ads : [ads];
            this.state.selectedIds = ads.map((a) => a.value + '');

            const val = this.node.multiple
                ? this.state.selectedIds
                : this.state.selectedIds[0];

            if (this.state.fullKey.length > 0) {
                this.$emit('changed', {
                    [this.state.fullKey]:
            val && val !== 'undefined' && val.length ? val : undefined
                });
            } else {
                console.log(
                    'value just changed in advertiser select component but there was no key?'
                );
            }
        }
    }
};
</script>
