import { opticsApi } from '@/lib/axios';

const state = {
    list: [],
    lastId: null
};

const getters = {
};

const mutations = {
    SET_TAGS(state, tags) {
        state.list = tags;
    },
    SET_LAST_TAG_ID(state, id) {
        state.lastId = id;
    }
};

const actions = {
    FETCH_TAGS: ({ commit, rootGetters }) => {
        return new Promise((resolve, reject) => {
            if (!rootGetters['user/USER_GROUPS'].length) {
                reject(new Error('No user groups'));
            }
            const groups = rootGetters['user/USER_GROUPS'];
            opticsApi.get(`/tag-manager/?groups=${groups.join(',')}`)
                .then(resp => {
                    commit('SET_TAGS', resp.data);
                    resolve(resp.data);
                }).catch(err => {
                    console.warn('[Error] ' + JSON.stringify(err));
                    reject(err);
                });
        });
    }
};

export default {
    namespaced: true, // auto namespace this module with its name
    state,
    getters,
    mutations,
    actions
};
