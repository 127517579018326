import AppInstance from '@/app';
import OktaVue, { LoginCallback } from '@okta/okta-vue';
import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import { routes } from './routes';
import { getAuthKey, getPermissions, isOktaAuthenticated, loginDirectlyAndKillOktaWithFireIfAllowed } from '@/assets/js/common/permissions';
import oktaAuth from '@/plugins/okta/auth';

// OKTA
const EXTERNAL_LOGIN_PATH = '/session/';
const PATH_TO_DEFAULT_PAGE = '/contextual';
const OKTA_CALLBACK_PATH = '/login/callback';

routes.unshift({
    path: OKTA_CALLBACK_PATH,
    component: LoginCallback
});

AppInstance.use(OktaVue, { oktaAuth });

const router = createRouter({
    routes,
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    }
});

router.beforeEach(async function (to, from, next) {
    // Enable login through external session key and bypass OKTA
    if (to.path && to.path.startsWith(EXTERNAL_LOGIN_PATH)) {
        const path = to.path;
        const sessionKey = path.substring(path.lastIndexOf('/') + 1).trim();
        if (sessionKey.length > 0) {
            store.commit('toggleSpinner', true);

            const external = await loginDirectlyAndKillOktaWithFireIfAllowed(sessionKey);

            store.commit('toggleSpinner', false);

            if (external && external.userId) {
                next({
                    path: '/'
                });
                return;
            }
        }
    }

    // If this is a redirect from OKTA, let it do its thing.
    if (to.path === OKTA_CALLBACK_PATH || to.path === '/error') {
        // If the user is still stuck on the OKTA redirect page, navigate to error page
        setTimeout(() => {
            if (location.pathname.endsWith('/login/callback')) {
                console.log('reroute...');
                next({ name: 'error', params: { msg: 'There was a problem signing in. Please refresh the page.' } });
            }
        }, 10000);

        next();
        return;
    }

    store.commit('toggleSpinner', true);

    /* If it is any other route than login,
    check the user permissions for the requested route. */
    let destination;
    const rootRoute = to.path[0] === '/' ? to.path.slice(1) : to.path;
    const paramlessRoute = `/${rootRoute.split('/')[0]}`;
    const isAuthenticated = await isOktaAuthenticated();

    getAuthKey();

    if (!isAuthenticated) {
        console.warn('not authenticated');

        if (paramlessRoute !== '/login' && paramlessRoute !== '/error') {
            console.info('redirecting to login');

            destination = {
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            };

        } else {
            destination = true;
        }
    } else {
        const {
            authorizedForApp,
            hasGroups,
            isPublic
        } = await getPermissions(paramlessRoute);

        /* If there is a mismatch between the user's okta login and our user record email address
        the user will be authenticated but lack all groups, which provide authorization. In such a case
        we need to ask the user for their alternate email address. */
        if (!hasGroups) {
            if (paramlessRoute !== '/landing') {
                destination = {
                    path: '/landing'
                };
            } else destination = true;
        } else if (paramlessRoute === '/login') {
            // Authenticated user will never be able to access /login
            destination = {
                path: PATH_TO_DEFAULT_PAGE
            };
        } else if (!authorizedForApp && !isPublic && paramlessRoute !== '/error') {
            // If an unauthorized user tries to access any private route
            console.warn('[Warn] User not authorized.');
            destination = {
                path: '/error'
            };
        } else {
            // complete the requested route
            destination = true;
        }
    }

    store.commit('toggleSpinner', false);
    next(destination);
});

export default router;
